<script lang="ts" setup>
import type { AliSectionPickedType } from 'gemx/src/modules/editor/modules/common/utils/types';
import { ref, watch } from 'vue';

type Props = {
  defaultValue?: AliSectionPickedType;
  value?: AliSectionPickedType;
  pickSectionLabel?: string;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'open'): void;
  (e: 'clear', value?: AliSectionPickedType): void;
}>();

const enteredInput = ref(!!props.value?.id.length);
const refInput = ref<HTMLInputElement>();
const val = ref<AliSectionPickedType>(props.value ?? props.defaultValue);

const onPickSection = () => {
  emit('open');
};
const handleClearSection = () => {
  emit('clear', props.defaultValue);
  refInput.value?.focus();
};

watch(
  () => props.value,
  (value) => {
    if (value !== undefined) val.value = value;
  },
);
</script>
<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div>
      <div class="flex flex-col">
        <div class="flex items-start justify-between" :class="{ 'mb-16': enteredInput }">
          <div class="w-full">
            <div class="relative mb-8 w-full">
              <g-input
                ref="refInput"
                type="text"
                :disable="true"
                input-style="secondary"
                placeholder="Eg: Carousel Slider"
                :value="val?.name || ''" />
              <g-base-icon
                v-if="val?.id"
                name="close-round"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                class="text-dark-high absolute top-[calc(50%-8px)] right-8 cursor-pointer"
                @click="handleClearSection" />
            </div>
            <g-button
              type="secondary"
              button-classes="flex w-full items-center justify-center"
              size="medium"
              @click="onPickSection">
              <div class="absolute flex items-center justify-center">
                <g-base-icon name="pick-link" width="16" height="16"></g-base-icon>
                <span class="text-12 text-dark-high ml-8 font-medium">{{ pickSectionLabel || 'Pick a section' }}</span>
              </div>
            </g-button>
          </div>
        </div>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>
