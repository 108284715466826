<script setup lang="ts">
import { useInfiniteScroll } from '@vueuse/core';
import { ref } from 'vue';
import { getImageUrlPreview } from '../../helpers/image';

type Props = {
  id?: string | number;
  data: any;
  loading?: boolean;
  selectedItem: any;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'on-scroll'): void;
  (e: 'on-select-icon', value: string, id: string): void;
}>();

const iconBoundary = ref();
// const isChecked = ref();

useInfiniteScroll(
  iconBoundary,
  () => {
    emit('on-scroll');
  },
  { distance: 20 },
);
function readSVG(url: string, id?: string | undefined) {
  return fetch(url)
    .then((response) => response.text())
    .then((data) => {
      const serialize = new XMLSerializer();

      const domParserData = new DOMParser().parseFromString(data, 'image/svg+xml');
      const svgDom = domParserData.querySelector('svg');

      // add viewbox
      if (!svgDom?.getAttribute('viewBox') && svgDom?.getAttribute('width') && svgDom?.getAttribute('height')) {
        svgDom.setAttribute('viewBox', `0 0 ${svgDom.getAttribute('width')}  ${svgDom.getAttribute('height')}`);
      }
      let stringData = serialize.serializeToString(domParserData);

      if (stringData.includes('<style') && id) {
        const styleString = stringData.match(/<style(\n|.)*?<\/style>/g);
        const regex = /(\.[^}]+\{)/g;

        // add class in style tag
        if (styleString?.length) {
          const newStyle = styleString[0].replace(regex, (val) => {
            return `.id${id} ${val}`;
          });
          let newStringData = stringData.replace(styleString[0], newStyle);
          // add class to svg
          newStringData = newStringData.replace('<svg', `<svg data-id="${id}" class="id${id}"`);
          return newStringData;
        }
      }
      stringData = stringData.replace('<svg', `<svg data-id="${id}"`);
      return stringData;
    });
}
const changeUploadIcon = (path: string, id: string) => {
  readSVG(path, id).then((svg) => {
    emit('on-select-icon', svg, id);
  });
};
</script>

<template>
  <perfect-scrollbar ref="iconBoundary" class="scroll-icon-upload -mx-16 px-16">
    <div class="grid grid-cols-5 gap-8 pb-16">
      <div
        v-if="loading"
        class="bg-dark-300 relative flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[6px] hover:bg-[#515151]">
        <div class="h-32 w-32 animate-spin rounded-full border-2 border-solid border-t-[#676767]"></div>
      </div>
      <div
        v-for="icon in $props.data"
        :key="icon.node.id"
        class="bg-dark-300 flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[6px] hover:bg-[#515151]"
        :class="{
          'border border-[#3C67FF]': props.selectedItem?.id === icon.node.id,
        }"
        @click="() => changeUploadIcon(icon?.node.filePath, icon.node.id)">
        <img class="h-32 w-32" :src="getImageUrlPreview(icon?.node)" alt="image" />
      </div>
    </div>
  </perfect-scrollbar>
</template>

<style lang="scss">
.scroll-icon-upload .ps--scrolling-y {
  margin-bottom: -16px;
}
</style>
