<script setup lang="ts">
import { ref, watch, computed, inject } from 'vue';
import { watchDebounced } from '@vueuse/shared';
import type { PaddingType, SpacingOption, SpacingType, Spacing } from './types';
import { GBaseIcon } from '@gem/uikit';
import type { ObjectDevices, ScreenType } from '@gem/control';
import { GPopover } from '@gem/uikit';

const props = withDefaults(
  defineProps<{
    isHover?: boolean;
    label?: string;
    property: keyof PaddingType;
    value?: string;
    diffValue?: string;
    inside?: boolean;
    placeholder?: string;
    align?: 'left' | 'right';
    options: SpacingOption[];
    min?: number;
    controlChange?: (id: keyof PaddingType, value?: any) => void;
  }>(),
  {
    align: 'left',
  },
);

const emit = defineEmits<{
  (e: 'controlOnChange', property: keyof Spacing, value?: PaddingType): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'selectSpacing', property: keyof Spacing, isShow: boolean): void;
}>();

const globalSpacing = inject<Record<string, ObjectDevices<string>>>('globalSpacing');
const currentScreen = inject<string>('currentScreen');
const getSpacingUnit = (value: string) => {
  if (!globalSpacing) return 0;
  const realValue = value.replace('var(--g-s-', '').replace(')', '');
  const globalSpacingValue = globalSpacing[realValue];
  if (!globalSpacingValue) return realValue;
  else {
    const screenActive = (currentScreen as ScreenType) || 'desktop';
    return globalSpacingValue[screenActive] ? globalSpacingValue[screenActive] : globalSpacing[realValue]['desktop'];
  }
};

const getNumberValue = (defaultValue?: string) => {
  const value = defaultValue ? defaultValue : props.value;
  let result;

  if (value === 'auto' || !value) {
    result = 0;
  } else if (isNaN(parseInt(props.value || '0'))) {
    result = parseInt(getSpacingUnit(value) as string, 10);
  } else {
    result = parseInt(value);
  }
  return result;
};

const spacing = ref<SpacingType>({
  auto: 'auto',
  xxs: '--g-s-xxs',
  xs: '--g-s-xs',
  s: '--g-s-s',
  m: '--g-s-m',
  l: '--g-s-l',
  xl: '--g-s-xl',
  '2xl': '--g-s-2xl',
  '3xl': '--g-s-3xl',
  '4xl': '--g-s-4xl',
  '5xl': '--g-s-5xl',
});
const dom = ref<HTMLDivElement>();
const isFocus = ref(false);
const isSettingGlobalValue = ref(false);

const getValueUnit = () => {
  const { value } = props;
  let result;
  if (value === 'auto' || !value) {
    result = 'px';
  } else {
    const regex = new RegExp(/(\d*\.?\d+)\s?(px|em|ex|%|in|cn|mm|pt|pc+)/g);
    result = regex.test(value) ? value.replace(/(-|\d)+/gi, '') : 'px';
  }
  return result;
};

const iniUnit = computed(() => getValueUnit());

function isEmpty<T>(obj: T extends Object ? T : never) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

const initialValue = (value?: string) => {
  if (!value || isEmpty(value)) return '0';
  if (value === 'auto') return 'auto';
  if (value === '-px') return '-';
  return Object.keys(spacing.value).find(
    (key) => spacing.value[key as keyof SpacingType] === value.slice(4, value.length - 1),
  );
};

const valueGlobal = computed(() => props.options.find((item) => item.id === val.value));
const val = ref(
  props.value !== undefined
    ? isNaN(parseInt(props.value))
      ? initialValue(props.value)
      : `${parseInt(props.value)}`
    : props.value ?? '0',
); // initial value

const displayValue = computed(() => {
  if (val.value === 'auto') return 'Auto';
  if (valueGlobal.value) {
    return `${parseFloat(valueGlobal.value?.value as string)}`;
  } else {
    return val.value;
  }
});

const watchV = computed(() => getNumberValue(val.value));

watchDebounced(
  () => props.diffValue,
  () => {
    const { property, value } = props;
    if (value != val.value) {
      props.controlChange?.(property, val.value + iniUnit.value);
    }
  },
);

watch(
  () => props.diffValue,
  (diffTo) => {
    let result;
    const { property, value } = props;
    result = watchV.value + parseInt((diffTo || 0) as string);

    if (isNaN(result)) {
      result = 0;
    }

    if (typeof props.min === 'number' && result < props.min) {
      result = props.min;
    }

    const final = `${result}`;
    if (value !== final) {
      val.value = final;
      emit('controlOnChange', property, final + iniUnit.value);
    }
  },
);

watch(
  () => props.value,
  (newVal) => {
    if (!newVal) {
      val.value = undefined;
    } else if (newVal === 'auto') {
      val.value = 'auto';
    } else if (isNaN(parseInt(newVal))) {
      val.value = initialValue(newVal);
    } else {
      val.value = replaceStringToDigits(newVal);
    }
  },
);

const replaceStringToDigits = (value: string) => {
  if (props.label === 'padding') return value.replace(/\D/g, '');
  return value.replace(/(^-?\d*\.?\d+)(.+)/g, '$1');
};

const handleOnChange = () => {
  emit(
    'controlOnChange',
    props.property,
    val.value ? (val.value >= 0 ? parseInt(val.value) : val.value) + iniUnit.value : undefined,
  );
};

const handleChange = () => {
  const DEFAULT_VALUE = '0px';

  if (!val.value) {
    props.controlChange?.(props.property, DEFAULT_VALUE);
  } else if (val.value === 'auto') {
    props.controlChange?.(props.property, 'auto');
  } else if (isNaN(parseInt(val.value))) {
    props.controlChange?.(props.property, 'var(' + spacing.value[val.value as keyof SpacingType] + ')');
  } else {
    val.value = parseInt(val.value).toString();
    props.controlChange?.(props.property, parseInt(val.value) + iniUnit.value);
  }
};

const chooseSpacingGlobal = (value: string) => {
  isSettingGlobalValue.value = true;
  val.value = value;
  if (val.value === 'auto') {
    props.controlChange?.(props.property, 'auto');
  } else {
    const globalValue = 'var(' + spacing.value[val.value as keyof SpacingType] + ')';
    props.controlChange?.(props.property, globalValue);
    isSettingGlobalValue.value = false;
  }
};

const onClickSubAction = (type: string, value?: any) => {
  emit('onClickSubAction', type, value);
};

const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  val.value = target?.value;
  handleOnChange();
};

const onOpenSelectSpacing = () => {
  emit('selectSpacing', props.property, true);
};

const onCloseSelectSpacing = () => {
  emit('selectSpacing', props.property, false);
};
</script>
<template>
  <g-popover
    cls="!p-0"
    :has-arrow="false"
    :closeable="true"
    :overlay="false"
    overlay-container="#root-modal"
    :padding-right="23"
    placement="bottom"
    @close="onCloseSelectSpacing"
    @open="onOpenSelectSpacing">
    <template #default="{}">
      <div
        class="rounded-medium"
        :class="{
          '!border-primary-275': isFocus,
          top: property === 'top',
          left: property === 'left',
          bottom: property === 'bottom',
          right: property === 'right',
          inside: inside,
        }">
        <div ref="dom" class="gemx-dragger rounded-medium cursor-row-resize">
          <div
            class="hover:bg-dark-200 group relative flex h-[26px] w-[41px] items-center justify-center rounded-xl border border-transparent"
            :class="{ 'border-primary-300 bg-dark-400': isFocus }">
            <input
              :value="displayValue"
              type="text"
              :title="val"
              :class="{
                'bg-light-high': isHover,
              }"
              class="text-12 font-regular placeholder:text-12 text-dark-high placeholder:text-dark-disabled peer h-full w-full rounded-xl bg-transparent text-center leading-none outline-none"
              @input="handleInput"
              @focus="isFocus = true"
              @focusout="isFocus = false"
              @onChange.stop="handleOnChange"
              @change.stop="handleChange" />
          </div>
        </div>
      </div>
    </template>
    <template #content="{ close }">
      <div
        data-test="editor-control-spacing-list"
        class="bg-dark-400 shadow-4dp rounded-12 absolute w-[132px] p-4 transition-all">
        <perfect-scrollbar class="h-[125px]">
          <div
            v-for="opt of options"
            :key="opt.id"
            data-test="editor-control-spacing-option"
            class="text-12 font-regular hover:bg-light-100/20 text-light-100 relative flex w-full cursor-pointer items-center truncate whitespace-nowrap rounded-xl py-8 pl-24 pr-10 leading-[14px]"
            @click="
              () => {
                chooseSpacingGlobal(opt.id);
                close();
              }
            ">
            <span v-if="val === opt.id" class="text-dark-high absolute inset-y-0 left-4 flex items-center">
              <GBaseIcon name="status-check" width="16" height="16" />
            </span>
            <p class="flex w-full items-center justify-between truncate">
              <span class="truncate">{{ opt.name }}</span>
              <span class="text-dark-low">{{ opt.value }}</span>
            </p>
          </div>
          <p
            class="text-12 text-primary-200 border-light-500/[0.15] cursor-pointer border-t pt-8 pb-4 text-center font-medium leading-[18px]"
            @click.stop="onClickSubAction('editGlobalStyle', 'gs-spacing')"
            @mousedown.stop="onClickSubAction('editGlobalStyle', 'gs-spacing')">
            Edit global style
          </p>
        </perfect-scrollbar>
      </div>
    </template>
  </g-popover>
</template>
