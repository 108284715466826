import useEditorStore from "../../common/stores/editor";

export const useSaleFunnel = ()=>{
  const editorStore = useEditorStore();
  const editingPageType = computed(() => {
    return editorStore.getEditingPageType ?? '';
  });

  const isPostPurchasePage = computed(() => {
    return editingPageType.value === 'POST_PURCHASE';
  });

  return {
    isPostPurchasePage
  }
}
