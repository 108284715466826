import type { FilterType, Tab } from '@gem/uikit';
import { defineStore } from 'pinia';
import { DEFAULT_SELECTED_TAB, SORT_SAVED_LIBRARY_ACTIVE_ID } from '../utils/const';
import type {
  LibraryTemplatesQueryResponse,
  LibraryTemplateSelectFragment,
} from '@/api/app/queries/library-template.generated';
import type {
  LibrarySectionSelectFragment,
  LibrarySectionsQueryResponse,
} from '@/api/app/queries/library-sections.generated';

type AnchorType = {
  cid: string | undefined;
  direction: 'before' | 'after';
};

type SectionToSave = {
  uid?: string;
  name?: string;
  isThemeSection?: boolean;
};

export type State = {
  isOpen: boolean;
  isSaveToLibrary: boolean;
  isSavingToLibrary: boolean;
  sectionToSave: SectionToSave;
  anchor: AnchorType;
  filter: FilterType;
  isOpenConfirmModal: boolean;
  selectedTemplateId?: string;
  tempSelectThemeId?: string;
  clearAllSectionData: boolean;
  categoriesIds?: string[];
  previewItem: LibraryTemplateSelectFragment | LibrarySectionSelectFragment | null;
  tabs: Tab;
  totalThemeSection: number;
  isShowIntroduceThemeSection: boolean;
  allCacheKeyThemeSection: string[];
  isRenamedThemeSection: boolean;
  sectionsGlobalData: LibrarySectionsQueryResponse[] | null;
  themesPageGlobalData: LibraryTemplatesQueryResponse[] | null;
};

export const useLibraryStore = defineStore('library', {
  state: (): State => ({
    isOpen: false,
    isSaveToLibrary: false,
    isSavingToLibrary: false,
    sectionToSave: {
      uid: undefined,
      name: undefined,
      isThemeSection: false,
    },
    anchor: {
      cid: undefined,
      direction: 'after',
    },
    filter: DEFAULT_SELECTED_TAB,
    isOpenConfirmModal: false,
    selectedTemplateId: undefined,
    tempSelectThemeId: undefined,
    clearAllSectionData: false,
    categoriesIds: undefined,
    previewItem: null,
    tabs: {
      global: { content: 'Sections', disabled: false },
      templates: { content: 'Templates', disabled: false },
      savedLibrary: {
        content: 'Saved Library',
        isShowFilterSort: true,
        sortActive: SORT_SAVED_LIBRARY_ACTIVE_ID,
        disabled: false,
      },
    },
    isShowIntroduceThemeSection: false,
    allCacheKeyThemeSection: [],
    totalThemeSection: 0,
    isRenamedThemeSection: false,
    sectionsGlobalData: null,
    themesPageGlobalData: [],
  }),
  getters: {
    getTabs(state) {
      return state.tabs;
    },
    getActiveTab(state) {
      return state.tabs[state.filter];
    },
    getFilter(state) {
      return state.filter;
    },
    getAnchor(state) {
      return state.anchor;
    },
    getIsOpen(state) {
      return state.isOpen;
    },
    getIsSaveToLibrary(state) {
      return state.isSaveToLibrary;
    },
    getIsSavingToLibrary(state) {
      return state.isSavingToLibrary;
    },
    getCategoriesIds(state) {
      return state.categoriesIds;
    },
    getSectionToSave(state) {
      return state.sectionToSave;
    },
    getPreviewItem(state): LibraryTemplateSelectFragment | LibrarySectionSelectFragment | null {
      return state.previewItem;
    },
    getTotalThemeSection(state) {
      return state.totalThemeSection;
    },
    getIsShowIntroduceThemeSection(state) {
      return state.isShowIntroduceThemeSection;
    },
    getCacheAllKeyThemeSection(state) {
      return state.allCacheKeyThemeSection;
    },
    getLastKeyThemeSection(state) {
      const totalKey = state.allCacheKeyThemeSection.length;
      return totalKey ? state.allCacheKeyThemeSection[totalKey - 1] : '';
    },
    getIsRenamedThemeSection(state) {
      return state.isRenamedThemeSection;
    },
    getSectionsGlobalData(state) {
      return state.sectionsGlobalData;
    },
    getThemesPageGlobalData(state) {
      return state.themesPageGlobalData;
    },

    getClearAllSectionData(state) {
      return state.clearAllSectionData;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    resetAnchor() {
      this.anchor.cid = undefined;
      this.anchor.direction = 'after';
    },
    setAnchor({ cid, direction }: AnchorType) {
      this.anchor.cid = cid;
      this.anchor.direction = direction;
    },
    setTabs(tabs: Tab) {
      this.tabs = tabs;
    },
    setIsOpen(value: boolean) {
      this.isOpen = value;
    },
    setIsSaveToLibrary(value: boolean) {
      this.isSaveToLibrary = value;
    },
    setIsSavingToLibrary(value: boolean) {
      this.isSavingToLibrary = value;
    },
    setFilter(value: FilterType) {
      this.filter = value;
    },
    setCategoriesIds(value?: string[]) {
      this.categoriesIds = value;
    },
    setSectionUidToSave(value?: string) {
      this.sectionToSave.uid = value;
    },
    setSectionNameToSave(value?: string) {
      this.sectionToSave.name = value;
    },
    setIsSaveThemeSection(value?: boolean) {
      this.sectionToSave.isThemeSection = value;
    },
    setPreviewItem(value: LibraryTemplateSelectFragment | LibrarySectionSelectFragment | null) {
      this.previewItem = value;
    },
    setTotalThemeSection(value: number) {
      this.totalThemeSection = value;
    },
    setIsShowIntroduceThemeSection(value: boolean) {
      this.isShowIntroduceThemeSection = value;
    },
    setCacheAllKeyThemeSection(value: string[]) {
      this.allCacheKeyThemeSection = value;
    },
    pushKeyThemeSectionToCache(value: string) {
      this.allCacheKeyThemeSection.push(value);
    },
    setIsRenamedThemeSection(value: boolean) {
      this.isRenamedThemeSection = value;
    },
    setSectionsGlobalData(value: LibrarySectionsQueryResponse[] | null) {
      this.sectionsGlobalData = value;
    },
    setThemesPageGlobalData(value: LibraryTemplatesQueryResponse[] | null) {
      this.themesPageGlobalData = value;
    },
    setClearAllSectionData(value: boolean) {
      this.clearAllSectionData = value;
    },
  },
});

export default useLibraryStore;
