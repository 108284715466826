import type * as Types from '../type/graphql';

export const getImageUrlByMimeType = (imageData?: Types.File) => {
  if (imageData?.mimeType && imageData?.filePath) {
    const mimeType = imageData?.mimeType;
    if (mimeType === 'image/svg+xml') return `${imageData.filePath}-/format/auto/`;
  }
  return imageData?.filePath || '';
};
export const getImageUrlPreview = (imageData?: Types.File) => {
  return `${getImageUrlByMimeType(imageData)}-/preview/180x180/`;
};
