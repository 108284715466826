<script lang="ts" setup>
import { GModal, GCollapseTransition } from '@gem/uikit';
import { ref, watch } from 'vue';
import type { File } from '../type/graphql';
import SwatchesColor from './swatches/SwatchesColor.vue';
import SwatchesImage from './swatches/SwatchesImage.vue';
import type { GlobalSwatchesData, SwatchesOptionType, SwatchesOptionValue } from './swatches/types';

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'uploadImage', formData: FormData, index?: string, toVariant?: string): void;
  (e: 'addItem', data: GlobalSwatchesData[]): void;
  (e: 'save'): void;
  (e: 'changeData', value: GlobalSwatchesData[]): void;
}>();

const props = withDefaults(
  defineProps<{
    data?: GlobalSwatchesData[];
    index: number;
    images?: File[];
    maximumSize?: number;
    allowedFiles?: string[];
    shopId: string;
    optionType: SwatchesOptionType;
    optionTitle: string;
    showOther: boolean;
    imageUploading: boolean;
  }>(),
  {
    data: () => [],
    maximumSize: 2 * 1024 * 1024, // Byte
    allowedFiles: () => [
      'image/avif',
      'image/jpeg',
      'image/tiff',
      'image/bmp',
      'image/gif',
      'image/png',
      'image/webp',
      'image/svg+xml',
    ],
    showOther: false,
    imageUploading: false,
  },
);

const dataClone = ref<GlobalSwatchesData[]>(props.data);
const isDataDiff = ref<boolean>(false);
const saveClicked = ref<boolean>(false);
const dataOriginal: GlobalSwatchesData[] = JSON.parse(JSON.stringify(dataClone.value));
const isColorNotSet = ref<boolean>(false);
const isImageNotSet = ref<boolean>(false);

const handleInitComponent = (optionType: SwatchesOptionType) => {
  switch (optionType) {
    case 'color':
      return SwatchesColor;
    case 'image':
      return SwatchesImage;
  }
};

const handleUploadImage = (formData: FormData, index: string, parentIndex: string) => {
  isDataDiff.value = true;
  emit('uploadImage', formData, index, parentIndex);
};

const closeModal = () => {
  emit('close');
};

const saveModal = () => {
  isColorNotSet.value = dataClone.value[props.index].optionValues.some((op) => {
    if (!op.colors?.length && !op.other) return true;
  });

  isImageNotSet.value = dataClone.value[props.index].optionValues.some((op) => {
    if (!op.imageUrl?.length && !op.other) return true;
  });

  if (!saveClicked.value && (props.optionType === 'color' ? isColorNotSet.value : isImageNotSet.value)) {
    saveClicked.value = true;
    return;
  }

  emit('save');
};

const handleChangeData = (dataChange: GlobalSwatchesData[]) => {
  const optionOriginal = dataOriginal[props.index].optionValues;
  const refOptionChange = dataChange[props.index].optionValues;
  isDataDiff.value = JSON.stringify(refOptionChange) !== JSON.stringify(optionOriginal);
  emit('changeData', dataChange);
};

watch(
  () => props.data,
  () => {
    dataClone.value = props.data;
  },
);
</script>

<template>
  <g-modal
    :is-open="true"
    :show-btn-close="true"
    :btn-ok-disable="!isDataDiff || imageUploading"
    :click-out-side-close="false"
    header-class="cursor-default"
    label-ok="Apply for all products"
    container-class="h-[680px] max-h-[calc(100vh_-_72px)]"
    body-class="h-full"
    @cancel="closeModal"
    @close="closeModal"
    @ok="saveModal">
    <template #title>
      <div class="font-semibold">Configure variants style</div>
    </template>
    <template #default>
      <div class="w-[900px]">
        <g-collapse-transition :duration="200">
          <component
            :is="handleInitComponent(optionType)"
            :option-title="optionTitle"
            :data="data"
            :index="index"
            :images="images"
            :shop-id="shopId"
            :maximum-size="maximumSize"
            :allowed-files="allowedFiles"
            :save-clicked="saveClicked"
            :is-data-diff="isDataDiff"
            :is-color-not-set="isColorNotSet"
            :is-image-not-set="isImageNotSet"
            :show-other="showOther"
            @upload-image="handleUploadImage"
            @change-data="handleChangeData" />
        </g-collapse-transition>
      </div>
    </template>
  </g-modal>
</template>
