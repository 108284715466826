import type { Spacing, SpacingOption } from './types';
import { type SpacingType } from './types';
import { cloneDeepObject } from '@gem/uikit/src/types/common';

export const spacing: SpacingType = {
  auto: 'auto',
  xxs: '--g-s-xxs',
  xs: '--g-s-xs',
  s: '--g-s-s',
  m: '--g-s-m',
  l: '--g-s-l',
  xl: '--g-s-xl',
  '2xl': '--g-s-2xl',
  '3xl': '--g-s-3xl',
  '4xl': '--g-s-4xl',
  '5xl': '--g-s-5xl',
};

function isEmpty<T>(obj: T extends Object ? T : never) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const initialValue = (value?: string) => {
  if (!value || isEmpty(value)) return '0';
  if (value === 'auto') return 'auto';
  if (value === '-px') return '-';
  return Object.keys(spacing).find((key) => spacing[key as keyof SpacingType] === value.slice(4, value.length - 1));
};

export const createInitVal = (value?: string) => {
  if (!value) return '0';
  if (isNaN(parseInt(value))) {
    return initialValue(value);
  } else {
    return `${parseInt(value)}`;
  }
};

export const createNumber = (initValue?: string, globalValue?: SpacingOption) => {
  if (initValue === 'auto') {
    return 'Auto';
  }
  if (globalValue) {
    return `${parseFloat(globalValue?.value as string)}`;
  }
  return initValue ?? '0';
};

export const updateSpacingToToolbar = ({
  property,
  value,
  options,
}: {
  property: keyof Spacing;
  value?: string;
  options: SpacingOption[];
}) => {
  const label = property.split('-')[0];
  const position = property.split('-')[1];

  const val = createInitVal(value); // initial value
  const valueGlobal = options.find((item) => item.id === val);
  const displayValue = createNumber(val, valueGlobal);
  if (label === 'margin' && position === 'bottom') {
    emitEventToBuilder('editor:toolbar:update-margin-bottom', { marginBottom: displayValue });
  } else if (label === 'padding' && position === 'left') {
    emitEventToBuilder('editor:toolbar:update-padding-left', { paddingLeft: displayValue });
  }
};

function emitEventToBuilder(eventName: string, data: any) {
  const $iframe = document.querySelector<HTMLIFrameElement>('.iframe');
  if ($iframe) {
    const $iframeWin = $iframe.contentWindow;
    const event = new CustomEvent(eventName, {
      bubbles: true,
      detail: typeof data === 'object' ? cloneDeepObject(data) : data,
    });
    if ($iframeWin) {
      $iframeWin.dispatchEvent(event);
    }
  }
}
