<script lang="ts" setup>
import { ref, watch } from 'vue';

type PropsType = {
  id?: string;
  value?: number;
  placeholder?: string;
  readonly?: boolean;
  min?: number;
  max?: number;
  controlChange?: (id: string, value?: any) => void;
};

let inputTimer: ReturnType<typeof setTimeout> | undefined;
const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  readonly: false,
  placeholder: '',
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId: string, value?: any): void;
}>();

const val = ref<number | undefined>(props.value ? props.value : 0);
const previousInputValue = ref<number | undefined>(props.value ? props.value : 0);

const isFocus = ref(false);
const interval = ref<any>();
const timeout = ref<any>();

watch(
  () => props.value,
  (newPropValue) => {
    if (!newPropValue) {
      return;
    }
    val.value = newPropValue || props.min || previousInputValue.value || 0;
  },
);

const checkRangeValue = (value: number | undefined): number => {
  if (!value) return props.min ? props.min : 0;
  if (value > props.max) return props.max;
  if (value < props.min) return props.min;
  return value;
};

const controlRelease = () => {
  clearInterval(interval.value);
  clearTimeout(timeout.value);
  interval.value = null;
  timeout.value = null;
};

const controlClick = (change: any) => {
  val.value += change;
  val.value = checkRangeValue(val.value);
  previousInputValue.value = val.value;
  props.controlChange?.(props.id, val.value);
};

const checkValStateIsInvalid = () => {
  if (val.value === undefined) {
    return false;
  }
  return val.value > props.max || val.value < props.min;
};

const onInputChange = () => {
  if (!val.value) {
    return;
  }
  if (checkValStateIsInvalid()) {
    return;
  }

  if (inputTimer) {
    clearTimeout(inputTimer);
  }

  inputTimer = setTimeout(() => {
    props.controlChange?.(props.id, checkRangeValue(val.value));
  }, 300);
};

const blur = () => {
  if (!val.value) {
    val.value = previousInputValue.value;
    props.controlChange?.(props.id, val.value);
    return;
  }
  val.value = checkRangeValue(val.value);
  previousInputValue.value = val.value;
};
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div
      data-test="editor-control-input-number"
      class="gemx-control gemx-control-input-number bg-dark-400 group rounded-xl border border-transparent"
      :class="{ 'border-primary-300': isFocus }">
      <div class="flex h-36 items-center justify-between rounded-xl" :class="{ 'group-hover:bg-dark-300': !isFocus }">
        <div>
          <g-button
            type="ghost"
            data-test="editor-control-button-number-down"
            class="text-dark-high flex h-36 w-36 items-center justify-center !p-0"
            :disabled="readonly"
            @click="controlClick(-1)"
            @mouseup="controlRelease">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.75 10C3.75 9.65482 4.02982 9.375 4.375 9.375H15.625C15.9702 9.375 16.25 9.65482 16.25 10C16.25 10.3452 15.9702 10.625 15.625 10.625H4.375C4.02982 10.625 3.75 10.3452 3.75 10Z"
                fill="#E2E2E2" />
            </svg>
          </g-button>
        </div>
        <div class="">
          <input
            v-model="val"
            data-test="editor-control-input-number-field"
            type="number"
            class="text-dark-high font-regular caret-primary-300 text-12 placeholder:text-dark-disabled hover:border-dark-300 hover:bg-dark-300 focus:border-primary-300 h-32 w-full rounded-none border-none px-8 text-center outline-none transition duration-200 disabled:cursor-not-allowed"
            @focus="() => (isFocus = true)"
            @focusout="() => (isFocus = false)"
            @blur="blur"
            @keydown.enter="blur"
            @input="onInputChange" />
        </div>
        <div>
          <g-button
            type="ghost"
            data-test="editor-control-button-number-up"
            class="text-dark-high flex h-36 w-36 items-center justify-center !p-0"
            :disabled="readonly"
            @click="controlClick(1)"
            @mouseup="controlRelease">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.625 4.375C10.625 4.02982 10.3452 3.75 10 3.75C9.65482 3.75 9.375 4.02982 9.375 4.375V9.375H4.375C4.02982 9.375 3.75 9.65482 3.75 10C3.75 10.3452 4.02982 10.625 4.375 10.625H9.375V15.625C9.375 15.9702 9.65482 16.25 10 16.25C10.3452 16.25 10.625 15.9702 10.625 15.625V10.625H15.625C15.9702 10.625 16.25 10.3452 16.25 10C16.25 9.65482 15.9702 9.375 15.625 9.375H10.625V4.375Z"
                fill="#E2E2E2" />
            </svg>
          </g-button>
        </div>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<style lang="postcss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
  background: inherit;
}
</style>
