<script lang="ts" setup>
import { computed } from 'vue';
import type { ScreenType } from '../../../types';
import OneColSvg from '../icons/one-col.vue';
import ContentLeftSvg from '../icons/content-left.vue';
import ContentRightSvg from '../icons/content-right.vue';

type LayoutValue = {
  display?: string;
  cols?: number[];
};

const props = withDefaults(
  defineProps<{
    value?: LayoutValue;
    fallback?: LayoutValue;
    maxCol?: number;
    hasSwapted?: boolean;
    device?: ScreenType;
  }>(),
  {
    maxCol: 1,
  },
);

const columnWidth = computed(() => props.value?.display);
const emit = defineEmits<{
  (e: 'change', value: number): void;
  (e: 'swap-order', contentSide: 'left' | 'right'): void;
}>();

const handleChangeLayout = (cols: number) => {
  emit('change', cols);
};
const swapOrder = (contentSide: 'left' | 'right') => {
  emit('swap-order', contentSide);
};
const swapted = computed(() => props.hasSwapted);

const layoutData = computed(() => [
  {
    active: props.value?.cols?.length === 1 && columnWidth.value === 'fill',
    icon: OneColSvg,
    onClick: () => {
      handleChangeLayout(1);
    },
  },
  {
    active: props.value?.cols?.length === 2 && !swapted.value && columnWidth.value === 'fill',
    icon: ContentLeftSvg,
    hidden: props.maxCol <= 1 && props.device !== 'desktop',
    onClick: () => {
      handleChangeLayout(2);
      swapOrder('left');
    },
  },
  {
    active: props.value?.cols?.length === 2 && swapted.value && columnWidth.value === 'fill',
    icon: ContentRightSvg,
    hidden: props.maxCol <= 1 && props.device !== 'desktop',
    onClick: () => {
      handleChangeLayout(2);
      swapOrder('right');
    },
  },
]);
</script>

<template>
  <slot></slot>
  <div class="grid w-full grid-cols-3 gap-[13px]">
    <div
      v-for="(item, index) in layoutData"
      v-show="!item.hidden"
      :key="index"
      class="bg-dark-400 group flex h-[58px] cursor-pointer items-center justify-center overflow-hidden rounded-xl border-[1px] border-transparent"
      :class="{
        'border-primary-300 active': item.active,
      }"
      @click="!item.active && item.onClick()">
      <component :is="item.icon" />
    </div>
  </div>
</template>
