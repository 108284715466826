import type { Placement } from '@floating-ui/core';
import type { ButtonType } from './button';

export const cloneDeepObject = <T = unknown, R = T>(input: T) => {
  return JSON.parse(JSON.stringify(input)) as R;
};
export type ModeType = 'dark' | 'light';
export type Maybe<T> = T | undefined;
export type ModalProps = {
  type?: 'normal' | 'confirm';
  hideOverlay?: boolean;
  contentFixed?: boolean;
  hideHeader?: boolean;
  hideActions?: boolean;
  hideBtnCannel?: boolean;
  showBtnClose?: boolean;
  showBtnRefresh?: boolean;
  modalClass?: string;
  containerClass?: string;
  headerClass?: string;
  bodyClass?: string;
  footerClass?: string;
  dialogClass?: string;
  headerTitleClass?: string;
  loading?: boolean;
  refreshing?: boolean;
  isOpen?: boolean;
  btnOkDisable?: boolean;
  btnCancelType?: ButtonType;
  btnOkType?: ButtonType;
  labelCancel?: string;
  labelOk?: string;
  isDraggable?: boolean;
  initialVariable?: {
    x: number;
    y: number;
  };
  hrClass?: string;
  showHr?: boolean;
  showLineTop?: boolean;
  showLineBottom?: boolean;
  cancelBtnClass?: string;
  okBtnClass?: string;
  okBtnOnlyIcon?: boolean;
  closeBtnClass?: string;
  insideCloseBtnClass?: string;
  closeTooltipContent?: string;
  placementCloseTooltip?: Placement;
  hiddenTransition?: boolean;
  clickOutSideClose?: boolean;
  showLineCustom?: string;
};
