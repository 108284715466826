<script lang="ts" setup>
import type { ProductOption, SelectedOption } from '../type/common';
import type { Product } from './modal/MultiProductModal.vue';
import MultiProductModal from './modal/MultiProductModal.vue';
import { computed, ref } from 'vue';
import { replaceImageToSize } from '../hook/useResizeImage';
import { GReorder } from '@gem/uikit';
import { isDefined } from '../utils/common';

type Props = {
  id?: string;
  value?: string[];
  productDisplay?: Product[];
  isLoading?: boolean;
  options?: ProductOption[];
  variants?: SelectedOption[];
  productList?: Product[];
  max?: number;
  connectStatus?: boolean;
  syncPercent?: number;
  pageType?: string;
  inputSearchComponent?: Object;
};

type Item = {
  id?: string;
  title?: string;
  images?: string;
  handle?: string;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: Product[]): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
  (e: 'controlOnSearch', controlId?: string | number, value?: any): void;
  (e: 'changeVariants', value?: any): void;
  (e: 'controlRefresh'): void;
  (e: 'connect-shopify'): void;
  (e: 'showMore', action?: 'fetch'): void;
}>();

const productListVisibility = ref<boolean>(false);
const FIRST_VIEW_ITEM = 4;
const end = ref(FIRST_VIEW_ITEM);

const deleteItems = ref(0);

const closeModal = () => {
  productListVisibility.value = false;
};

const openModal = () => {
  productListVisibility.value = true;
  emit('showMore'); // trigger  for wrap know product list modal is open
};

const setValueSelected = (productList?: Product[]) => {
  emit('controlChange', props.id, productList || []);
};

const onSearch = (value?: string) => {
  emit('controlOnSearch', props.id, value);
};

const cloneList = computed(() => {
  return props.value?.map((id) => props.productDisplay?.find((el) => el.id === id)).filter(isDefined);
});

const deleteSingleItem = (id: string) => {
  const newProductList = cloneList.value?.filter((v) => v?.id !== id);
  emit('controlChange', props.id, newProductList);
  deleteItems.value++;
};

const next = () => {
  if (cloneList.value?.length ?? 0 <= end.value) {
    end.value = cloneList.value?.length ?? 0;
  }
};

const prev = () => {
  if ((cloneList.value?.length ?? 0 >= end.value) && end.value > FIRST_VIEW_ITEM) {
    end.value = FIRST_VIEW_ITEM;
  }
};

const list = computed(() => {
  if (props.value && props.value.length == 0) {
    return [];
  }
  if (cloneList.value?.length === 0) {
    return [];
  } else if (cloneList.value?.length ?? 0 > end.value) {
    return [...(cloneList.value?.slice(0, end.value) ?? [])].map((v) => ({
      id: v?.id,
      title: v?.title,
      image: v?.image,
      handle: v?.handle,
    }));
  } else return [];
});

function handleReorder(items: Item[], _from?: number, _to?: number) {
  if (items.length) {
    emit(
      'controlChange',
      props.id,
      cloneList.value?.map((v, index) => {
        if (index < end.value) {
          return items[index] as Product;
        }
        return v;
      }),
    );
  }
}
</script>
<template>
  <div class="gemx-control">
    <div>
      <div class="flex flex-col gap-8">
        <div v-if="list && list.length" class="bg-dark-500 rounded-medium mx-[-7px] flex flex-col gap-4 p-8">
          <GReorder :items="list" @re-order="handleReorder">
            <template #default="{ item }">
              <div
                class="rounded-medium bg-dark-500 group-[.sortable-fallback]/sortable-item:bg-dark-500 group-[.sortable-ghost]/sortable-item:bg-dark-400 group-[&:not(.dragging)]/sortable:hover:bg-dark-400 group flex h-[48px] w-[248px] cursor-ns-resize items-center gap-8 p-8">
                <img
                  v-if="item?.image"
                  :alt="item?.title"
                  class="rounded-medium flex aspect-square h-32 w-32 shrink-0 overflow-hidden object-cover"
                  :src="replaceImageToSize(item?.image ?? '', '100x100') ?? item?.image" />
                <g-base-icon
                  v-else
                  class="text-dark-high flex-none"
                  width="36px"
                  height="36px"
                  view-box="0 0 24 24"
                  name="no-image" />
                <p class="text-12 text-light-100 font-regular line-clamp-2">
                  {{ (item as any)?.title }}
                </p>
                <g-button
                  type="ghost"
                  class="text-dark-high ml-auto hidden h-32 w-32 flex-none items-center justify-center !p-0"
                  :class="{ 'group-hover:flex': list && list.length > 1 }"
                  @click="deleteSingleItem((item as any)?.id)">
                  <g-base-icon name="close-round" width="16" height="16" viewBox="0 0 16 16"></g-base-icon>
                </g-button>
              </div>
            </template>
          </GReorder>
          <div
            v-if="
              (end < (cloneList?.length ?? 0) || end > FIRST_VIEW_ITEM) && (cloneList?.length ?? 0) > FIRST_VIEW_ITEM
            "
            class="dark mb-4 flex h-32 justify-between gap-8">
            <g-button
              class="text-12 flex flex-1 items-center justify-center gap-8 !px-0"
              :disable="end <= FIRST_VIEW_ITEM"
              type="secondary"
              @click="prev()">
              <g-base-icon name="minus-gemx" width="16px" height="16px" view-box="0 0 16 16"></g-base-icon>
              <p>Show less</p>
            </g-button>
            <g-button
              type="secondary"
              :disable="end >= (cloneList?.length ?? 0)"
              class="text-12 flex flex-1 items-center justify-center gap-8 !px-0"
              @click="next()">
              <g-base-icon name="plus-gemui" width="16px" height="16px" view-box="0 0 16 16"></g-base-icon>
              <p>Show more</p>
            </g-button>
          </div>
        </div>
        <div v-else class="bg-dark-400 rounded-medium flex h-[48px] items-center gap-8 p-8">
          <div class="rounded-medium aspect-square h-32 w-32 shrink-0 overflow-hidden object-cover">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.10051 6.10051C11.5678 0.633164 20.4322 0.633164 25.8995 6.10051C31.3668 11.5678 31.3668 20.4322 25.8995 25.8995C20.4322 31.3668 11.5678 31.3668 6.10051 25.8995C0.633164 20.4322 0.633164 11.5678 6.10051 6.10051ZM23.7491 6.83666C19.0344 2.84053 11.9629 3.06655 7.51472 7.51472C3.06655 11.9629 2.84053 19.0344 6.83666 23.7491L23.7491 6.83666ZM8.25087 25.1633C12.9656 29.1595 20.0371 28.9335 24.4853 24.4853C28.9335 20.0371 29.1595 12.9656 25.1633 8.25087L8.25087 25.1633Z"
                fill="#757575" />
            </svg>
          </div>
          <p class="text-dark-high font-regular text-12 line-clamp-2 leading-[14px]">Product not found</p>
        </div>
        <div class="flex">
          <g-button
            type="tertiary"
            button-classes="flex h-[36px] w-full items-center justify-center gap-8 bg-dark-300"
            @click="openModal">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5 1.35181C10.8452 1.35181 11.125 1.63163 11.125 1.97681V3.15303C14.4232 3.45024 17.0498 6.07679 17.347 9.375H18.5234C18.8686 9.375 19.1484 9.65482 19.1484 10C19.1484 10.3452 18.8686 10.625 18.5234 10.625H17.347C17.0498 13.9232 14.4232 16.5498 11.125 16.847V18.0234C11.125 18.3686 10.8452 18.6484 10.5 18.6484C10.1548 18.6484 9.875 18.3686 9.875 18.0234V16.847C6.57679 16.5498 3.95024 13.9232 3.65302 10.625H2.47673C2.13155 10.625 1.85173 10.3452 1.85173 10C1.85173 9.65482 2.13155 9.375 2.47673 9.375H3.65302C3.95024 6.07679 6.57679 3.45024 9.875 3.15303V1.97681C9.875 1.63163 10.1548 1.35181 10.5 1.35181ZM4.90933 10.625H7.47673C7.82191 10.625 8.10173 10.3452 8.10173 10C8.10173 9.65482 7.82191 9.375 7.47673 9.375H4.90933C5.19755 6.76774 7.26774 4.69755 9.875 4.40933V6.97681C9.875 7.32198 10.1548 7.60181 10.5 7.60181C10.8452 7.60181 11.125 7.32198 11.125 6.97681V4.40933C13.7323 4.69755 15.8025 6.76774 16.0907 9.375H13.5234C13.1783 9.375 12.8984 9.65482 12.8984 10C12.8984 10.3452 13.1783 10.625 13.5234 10.625H16.0907C15.8025 13.2323 13.7323 15.3025 11.125 15.5907V13.0234C11.125 12.6782 10.8452 12.3984 10.5 12.3984C10.1548 12.3984 9.875 12.6782 9.875 13.0234V15.5907C7.26774 15.3025 5.19755 13.2323 4.90933 10.625Z"
                fill="white" />
            </svg>
            <p class="text-12 text-white">Pick products</p>
          </g-button>
        </div>
      </div>
      <MultiProductModal
        :value="cloneList"
        :list="productList"
        :type="'Product'"
        :open="productListVisibility"
        :is-loading="isLoading"
        :max="max"
        :sync-percent="syncPercent"
        :page-type="pageType"
        :inputSearchComponent="inputSearchComponent"
        @show-more="emit('showMore', 'fetch')"
        @refresh="emit('controlRefresh')"
        @close="closeModal"
        @set-value-selected="setValueSelected"
        @on-search="onSearch" />
    </div>
  </div>
</template>
