import { sentryCaptureException } from '../../common/use-cases/sentry';

const counter: {
  historyCreate: {
    time: number;
    date: Date | null;
  };
} = {
  historyCreate: {
    time: 0,
    date: null,
  },
};

export const validateCounterHistoryCreate = (data: object) => {
  const now = new Date();
  if (!counter.historyCreate.date) {
    counter.historyCreate.time = 1;
    counter.historyCreate.date = now;
    return;
  }
  if (counter.historyCreate.date) {
    const miniSeconds = now.getTime() - new Date(counter.historyCreate.date).getTime();
    if (miniSeconds >= 1000) {
      counter.historyCreate.time = 1;
      counter.historyCreate.date = now;
      return;
    } else {
      counter.historyCreate.time++;
      if (counter.historyCreate.time >= 100) {
        sentryCaptureException('validateCounterHistoryCreate', 'Creating too much data into history', {
          data,
        });
      }
    }
  }
};
