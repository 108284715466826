<script lang="ts" setup>
import { OptionSelect } from '@gem/uikit';
import { computed, ref, watch, onMounted } from 'vue';
import IconSystem from './icon-picker/IconSystem.vue';
import IconUpload from './icon-picker/IconUpload.vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';

const PLACEHOLDER = 'E.g. <svg fill="currentColor"...';
type Icon = { name: string; id: string; data: string };

const tabs = {
  system: 'System',
  uploaded: 'Uploaded',
};

type propsType = {
  id?: string | number;
  value?: string;
  data?: Icon[];
  loading?: boolean;
  customLabel?: string;
  hideInput?: boolean;
  hideLabel?: boolean;
  iconUploaded?: any;
  shopId: string;
  isLoadingUpload?: boolean;
  styleIcon?: string;
  hiddenUpload?: boolean;
};

const props = defineProps<propsType>();
const emit = defineEmits<{
  (e: 'controlChange', controlId?: number | string, value?: string, iconSource?: string): void;
  (e: 'controlSearch', value?: string): void;
  (e: 'show-more', source: string): void;
  (e: 'on-search', value: string): void;
  (e: 'on-change-type', val: string): void;
  (e: 'tracking-pick-icon', source: string, style: string): void;
  (e: 'tracking-search-icon', keyword: string): void;
  (e: 'tracking-upload-icon', source: string): void;
  (e: 'on-upload', formData: FormData): void;
  (e: 'show-more-uploaded'): void;
  (
    e: 'on-error',
    type: 'error' | 'success',
    msg: string,
    time?: number,
    customObject?: { top?: boolean; hasCustom?: boolean; description?: string },
  ): void;
}>();

const selectedItem = ref({ id: '', value: '' });
const realValue = ref(props.value);
const refInput = ref<HTMLInputElement>();
const size = ref('medium');
const isLightMode = ref(false);
const selectedTab = ref<'system' | 'uploaded'>('system');
const searchValue = ref('');

watch(
  () => props.value,
  (val) => {
    realValue.value = val;
  },
);

const onShowMoreIconSystem = () => {
  emit('show-more', 'system');
};

const change = (svgIcon: string, iconSource?: string) => {
  realValue.value = svgIcon;
  emit('controlChange', props.id, svgIcon, iconSource);
};

const options = computed(() => {
  return props.data?.map((record) => {
    return {
      ...record,
      value: record.data,
    };
  });
});

const selectedIndex = computed(() => {
  const index = Object.keys(tabs).findIndex((key) => key === selectedTab.value);
  return index !== -1 ? index : 0;
});

const handleSelectedTab = (tab: string) => {
  if (selectedTab.value == tab) return;
  if (tab == 'system') {
    selectedTab.value = 'system';
  } else {
    selectedTab.value = 'uploaded';
  }
};

const onClear = () => {
  realValue.value = '';
  refInput.value?.focus();
  emit('controlChange', props.id, '');
};

const onSelectStyle = (val: string) => {
  emit('on-change-type', val);
};

const onSearch = (val: string) => {
  searchValue.value = val;
  emit('on-search', val);
};

const handleUpload = (formData: FormData) => {
  emit('on-upload', formData);
};

const onShowMoreIconUpload = () => {
  emit('show-more', 'uploaded');
};

const onSelect = (value: string, id: string, source?: string) => {
  if (source === 'system') {
    selectedItem.value.value =
      `<svg height="20" width="20" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 256 256" fill="currentColor" data-id="${id}">
              <path fill="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="currentColor" d="` +
      value +
      `" /></svg>`;
  } else {
    selectedItem.value.value = value;
  }
  selectedItem.value.id = id;
  onSearch('');
  change(selectedItem.value.value, Object.keys(tabs)[selectedIndex.value]);
};

const onError = (
  type: 'error' | 'success',
  msg: string,
  time?: number,
  customObject?: { top?: boolean; hasCustom?: boolean; description?: string },
) => {
  emit('on-error', type, msg, time, customObject);
};

onMounted(() => {
  selectedItem.value.id =
    realValue.value
      ?.match(/data-id="\d+"/)
      ?.at(0)
      ?.match(/\d+/)
      ?.at(0) || '';
});
</script>

<template>
  <div class="flex items-center justify-between" :class="{ '!justify-end': hideLabel }">
    <div v-if="!hideLabel" class="text-12 text-dark-low font-regular whitespace-nowrap">
      {{ customLabel ? customLabel : 'Choose icon' }}
    </div>
    <g-popover
      :has-arrow="false"
      :closeable="true"
      :overlay="false"
      :no-shadow-box="true"
      overlay-container="#root-modal"
      wrapper-class="w-[348px] !left-[63px] "
      placement="bottom-end"
      @close="searchValue = ''">
      <template #default="{ open }">
        <div
          data-test="editor-setting-control-icon-picker"
          class="bg-dark-300 hover:bg-dark-200 flex h-36 w-[60px] cursor-pointer items-center justify-between rounded-xl border border-transparent px-8 transition-all duration-200"
          :class="{
            'border-[#3C67FF]': open,
          }">
          <span class="text-12 inline-block truncate">
            <div
              v-if="realValue"
              class="wrap-icon text-white"
              :class="[realValue !== 'Mixed' ? 'w-20' : 'w-100', 'h-20']"
              v-html="realValue"></div>
            <div v-else></div>
          </span>

          <svg
            v-if="size == 'small'"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.13017 6.11716C4.30374 5.96095 4.58515 5.96095 4.75871 6.11716L8 9.03431L11.2413 6.11716C11.4149 5.96095 11.6963 5.96095 11.8698 6.11716C12.0434 6.27337 12.0434 6.52663 11.8698 6.68284L8.31427 9.88284C8.1407 10.0391 7.8593 10.0391 7.68573 9.88284L4.13017 6.68284C3.95661 6.52663 3.95661 6.27337 4.13017 6.11716Z"
              :fill="isLightMode ? '#676767' : '#E2E2E2'" />
          </svg>
          <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.13313 7.62204C5.31064 7.45932 5.59845 7.45932 5.77596 7.62204L10 11.4941L14.224 7.62204C14.4016 7.45932 14.6894 7.45932 14.8669 7.62204C15.0444 7.78476 15.0444 8.04858 14.8669 8.21129L10.3214 12.378C10.1439 12.5407 9.8561 12.5407 9.67859 12.378L5.13313 8.21129C4.95562 8.04858 4.95562 7.78476 5.13313 7.62204Z"
              :fill="isLightMode ? '#676767' : '#E2E2E2'" />
          </svg>
        </div>
      </template>

      <template #content="{ close }">
        <div class="bg-dark-400 rounded-xxl gemx-icon-picker w-[348px]" data-test="editor-modal-icon-picker">
          <div class="flex items-center justify-between px-16 py-10" data-test="editor-modal-icon-picker-header">
            <p class="text-14 font-semibold">Icon picker</p>
            <div
              class="group -my-10 -mx-16 flex h-[52px] w-[52px] cursor-pointer items-center justify-center"
              @click="
                () => {
                  close();
                  searchValue = '';
                }
              ">
              <g-button type="ghost" size="small" class="group-hover:bg-dark-hover h-32 w-32 hover:bg-opacity-100">
                <g-base-icon class="shrink-0" name="close" width="16" height="16" view-box="0 0 20 20"></g-base-icon>
              </g-button>
            </div>
          </div>
          <div class="px-16" data-test="editor-modal-icon-picker-body">
            <TabGroup :selected-index="selectedIndex">
              <TabList
                class="border-dark-300 mb-16 ml-0 flex max-h-36 items-center gap-4 rounded-xl border-[1px] border-solid p-4"
                :class="{
                  hidden: props.hiddenUpload,
                }">
                <Tab
                  v-for="tab in Object.keys(tabs)"
                  :key="tab"
                  v-slot="{ selected }"
                  class="flex flex-1 items-center justify-center outline-none">
                  <div
                    class="flex h-[28px] w-full items-center justify-center rounded-[6px] text-center text-[12px] capitalize outline-none transition-colors duration-200 focus:outline-none"
                    :class="[
                      selected
                        ? 'text-dark-high bg-dark-blue'
                        : 'text-dark-low   hover:bg-dark-hover border-transparent',
                    ]"
                    @click="handleSelectedTab(tab)">
                    <p class="text-12 font-medium">
                      {{ Object(tabs)[tab] }}
                    </p>
                  </div>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel class="outline-none">
                  <IconSystem
                    :search-val="searchValue"
                    :options="(options as OptionSelect[])"
                    :selected-item="selectedItem"
                    :on-search="onSearch"
                    :loading="$props.loading"
                    :style-icon="$props.styleIcon"
                    @on-scroll="onShowMoreIconSystem"
                    @on-search="onSearch"
                    @on-select-style="onSelectStyle"
                    @on-select-icon="(value: string, id: string) => {
                      onSelect(value, id, 'system');
                      close();
                    }"
                    @tracking-pick-icon="(style: string) => emit('tracking-pick-icon', 'system', style)" />
                </TabPanel>
                <TabPanel class="!h-[341]px outline-none">
                  <IconUpload
                    :id="props.id"
                    :data="(props.iconUploaded as OptionSelect[])"
                    :shop-id="props.shopId"
                    :loading="props.isLoadingUpload"
                    :selected-item="selectedItem"
                    @on-select-icon="(value: string, id: string) => {
                      onSelect(value, id, 'uploaded')
                      close();
                    }"
                    @upload-image="handleUpload"
                    @on-scroll="onShowMoreIconUpload"
                    @on-error="onError" />
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </div>
      </template>
    </g-popover>
  </div>
</template>

<style lang="scss">
.gemx-icon-picker {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12), 0px -4px 16px 0px rgba(0, 0, 0, 0.06);
}
.wrap-icon {
  svg {
    width: 20px;
    height: 20px;
  }
}
</style>
