import { getConfig } from '@/config';

export const getEmbedAppUrl = (shopDomain: string) => {
  try {
    const appId = getConfig('featureConfig')?.embedAppId || 'gempages-dev';

    const [shopName] = shopDomain.split('.');

    return `https://admin.shopify.com/store/${shopName}/apps/${appId}/app`;
  } catch (e) {
    return '';
  }
};
