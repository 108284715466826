import type { Maybe, PageSection } from '@/types/graphql';
import type { ActionOptions, Section, Component } from '../../common/utils/types';
import type { ActionSettingInput } from '../../common/use-cases/component';
import { componentLayoutDelete, componentForceUpdateConfigurations } from '../../common/use-cases/component';
import {
  componentCreate,
  componentDelete,
  componentUpdateSetting,
  componentMoveOnlyToSection,
} from '../../common/use-cases/component';
import {
  sectionCreate,
  sectionDelete,
  sectionMove,
  sectionUpdateDisplay,
  sectionUpdateName,
  moveComponentToNewSection,
  undoMoveComponentToNewSection,
  sectionInit,
  restoreSections,
} from '../../common/use-cases/section';
import { builderInit, builderUpdateComponent } from '../../preview/utils/builder';

type Action = {
  func: string;
  cids: string[];
  data: any;
  undo: (data: any) => void;
  redo: (data: any) => void;
};

type SectionInfo = {
  section: PageSection;
  position: { cid?: Maybe<string>; direction: 'before' | 'after' };
  options?: ActionOptions;
};

export const actionAddSection = <
  T extends {
    section: PageSection & Section;
    position: { cid?: Maybe<string>; direction: 'before' | 'after' };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionAddSection',
    cids: [data.section?.cid || ''],
    data,
    undo: ({ section, options }: T) => {
      if (section?.cid) {
        sectionDelete(section?.cid, {
          ...options,
          isHandleThemeSection: section?.isThemeSection,
        });
      }
    },
    redo: ({ section, position, options }: T) => {
      sectionCreate(section, position, {
        ...options,
        isHandleThemeSection: section?.isThemeSection,
      });
    },
  };
};

export const actionDuplicateSection = <
  T extends { cid: string; sectionDuplicated: PageSection; options?: ActionOptions },
>(
  data: T,
): Action => {
  return {
    func: 'actionDuplicateSection',
    cids: [data.sectionDuplicated?.cid || ''],
    data,
    undo: ({ sectionDuplicated, options }: T) => {
      if (sectionDuplicated) {
        sectionDelete(sectionDuplicated?.cid ?? '', options);
      }
    },
    redo: ({ cid, sectionDuplicated, options }: T) => {
      if (sectionDuplicated) {
        sectionCreate(sectionDuplicated, { cid, direction: 'after' }, options);
      }
    },
  };
};

export const actionDeleteSection = <
  T extends {
    section: PageSection;
    position: { cid?: Maybe<string>; direction: 'before' | 'after' };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionDeleteSection',
    cids: [data.section?.cid || ''],
    data,
    undo: ({ section, position, options }: T) => {
      sectionCreate(section, position, options);
    },
    redo: ({ section, options }: T) => {
      if (section?.cid) {
        sectionDelete(section.cid, options);
      }
    },
  };
};

export const actionMoveSection = <
  T extends { cid: string; oldAfterCid: string | null; afterCid: string | null; options?: ActionOptions },
>(
  data: T,
): Action => {
  return {
    func: 'actionMoveSection',
    cids: [data.cid],
    data,
    undo: ({ cid, oldAfterCid, options }: T) => {
      sectionMove(cid, oldAfterCid, options);
    },
    redo: ({ cid, afterCid, options }: T) => {
      sectionMove(cid, afterCid, options);
    },
  };
};

export const actionUpdateDisplaySection = <
  T extends { cid: string; oldValue: boolean; newValue: boolean; options?: ActionOptions },
>(
  data: T,
): Action => {
  return {
    func: 'actionUpdateDisplaySection',
    cids: [data.cid],
    data,
    undo: ({ cid, oldValue, options }: T) => {
      sectionUpdateDisplay(cid, oldValue, options);
    },
    redo: ({ cid, newValue, options }: T) => {
      sectionUpdateDisplay(cid, newValue, options);
    },
  };
};

export const actionUpdateNameSection = <
  T extends { cid: string; oldName: string; newName: string; options?: ActionOptions },
>(
  data: T,
): Action => {
  return {
    func: 'updateNameSection',
    cids: [data.cid],
    data,
    undo: ({ cid, oldName, options }: T) => {
      sectionUpdateName(cid, oldName, options);
    },
    redo: ({ cid, newName, options }: T) => {
      sectionUpdateName(cid, newName, options);
    },
  };
};

export const actionCreateComponent = <
  T extends {
    cid: string;
    uids: string[];
    newComponent: string;
    newComponents: Component[];
    parentData: { toParent: string; toIndex: number };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionCreateComponent',
    cids: [data.cid],
    data,
    undo: ({ uids, options }: T) => {
      componentDelete(uids, options);
    },
    redo: ({ cid, uids, newComponent, newComponents, parentData, options }: T) => {
      componentCreate({ cid, uids, newComponent, newComponents, parentData, options });
    },
  };
};

export const actionInsertLibraryToThemeSection = <
  T extends {
    cid: string;
    create: {
      uids: string[];
      newComponent: string;
      newComponents: Component[];
      parentData: { toParent: string; toIndex: number };
    };
    delete: {
      uids: string[];
      newComponent: string;
      newComponents: Component[];
      parentData: { toParent: string; toIndex: number };
    };
    configs: {
      oldComponent: Component;
      newComponent: Component;
    };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionInsertLibraryToThemeSection',
    cids: [data.cid],
    data,
    undo: () => {
      componentDelete(data.create.uids, data.options);
      componentForceUpdateConfigurations({
        cid: data.cid,
        component: JSON.stringify(data.configs.oldComponent),
        settings: {
          componentUid: data.configs.oldComponent.uid,
        },
        options: data.options,
      });
      componentCreate({ cid: data.cid, ...data.delete, options: data.options });
    },
    redo: () => {
      componentDelete(data.delete.uids, data.options);
      componentForceUpdateConfigurations({
        cid: data.cid,
        component: JSON.stringify(data.configs.newComponent),
        settings: {
          componentUid: data.configs.newComponent.uid,
        },
        options: data.options,
      });
      componentCreate({ cid: data.cid, ...data.create, options: data.options });
    },
  };
};

export const actionComponentDuplicate = <
  T extends {
    cid: string;
    uid: string;
    cloneUid: string;
    newComponent: string;
    newComponents: Component[];
    parentData: { toParent: string; toIndex: number };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionComponentDuplicate',
    cids: [data.cid],
    data,
    undo: ({ cloneUid, options }: T) => {
      componentDelete([cloneUid], options);
    },
    redo: ({ cid, uid, newComponent, newComponents, parentData, options }: T) => {
      componentCreate({
        cid,
        uids: [uid],
        newComponent,
        newComponents,
        parentData,
        options,
      });
    },
  };
};
export const actionDuplicateLayoutColumn = <
  T extends {
    cid: string;
    uid: string;
    cloneUid: string;
    rowUid: string;
    rowSettingLayoutOld: any;
    rowSettingLayoutNew: any;
    newComponent: string;
    newComponents: Component[];
    parentData: { toParent: string; toIndex: number };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionDuplicateLayoutColumn',
    cids: [data.cid],
    data,
    undo: ({ cloneUid, rowUid, rowSettingLayoutOld, options }: T) => {
      builderUpdateComponent({
        uid: rowUid,
        propName: 'layout',
        propValue: rowSettingLayoutOld,
        group: 'setting',
      });
      componentDelete([cloneUid], options);
    },
    redo: ({ cid, uid, newComponent, newComponents, parentData, rowUid, rowSettingLayoutNew, options }: T) => {
      builderUpdateComponent({
        uid: rowUid,
        propName: 'layout',
        propValue: rowSettingLayoutNew,
        group: 'setting',
      });
      componentCreate({
        cid,
        uids: [uid],
        newComponent,
        newComponents,
        parentData,
        options,
      });
    },
  };
};

export const actionDeleteLayoutColumn = <
  T extends {
    cid: string;
    uids: string[];
    rowUid: string;
    rowSettingLayoutOld: any;
    rowSettingLayoutNew: any;
    oldComponent: string;
    oldComponents: Component[];
    parentData: { toParent: string; toIndex: number };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionDeleteLayoutColumn',
    cids: [data.cid],
    data,
    undo: ({ cid, uids, oldComponent, oldComponents, parentData, rowUid, rowSettingLayoutOld, options }: T) => {
      builderUpdateComponent({
        uid: rowUid,
        propName: 'layout',
        propValue: rowSettingLayoutOld,
        group: 'setting',
      });
      componentCreate({
        cid,
        uids,
        newComponent: oldComponent,
        newComponents: oldComponents,
        parentData,
        options,
      });
    },
    redo: ({ uids, rowUid, rowSettingLayoutNew, options }: T) => {
      builderUpdateComponent({
        uid: rowUid,
        propName: 'layout',
        propValue: rowSettingLayoutNew,
        group: 'setting',
      });
      componentLayoutDelete(uids, options);
    },
  };
};
export const actionDeleteComponent = <
  T extends {
    cid: string;
    uids: string[];
    oldComponent: string;
    oldComponents: Component[];
    parentData: { toParent: string; toIndex: number };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionDeleteComponent',
    cids: [data.cid],
    data,
    undo: ({ cid, uids, oldComponent, oldComponents, parentData, options }: T) => {
      componentCreate({
        cid,
        uids,
        newComponent: oldComponent,
        newComponents: oldComponents,
        parentData,
        options,
      });
    },
    redo: ({ uids, options }: T) => {
      componentDelete(uids, options);
    },
  };
};

export const actionComponentUpdateSetting = <
  T extends {
    cid: string;
    oldComponent: string;
    newComponent: string;
    settings: ActionSettingInput[];
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionComponentUpdateSetting',
    cids: [data.cid],
    data,
    undo: ({ cid, oldComponent, settings, options }: T) => {
      componentUpdateSetting({
        cid,
        component: oldComponent,
        settings,
        options,
      });
    },
    redo: ({ cid, newComponent, settings, options }: T) => {
      componentUpdateSetting({
        cid,
        component: newComponent,
        settings,
        options,
      });
    },
  };
};

export const actionComponentForceUpdateConfigurations = <
  T extends {
    cid: string;
    oldComponent: string;
    newComponent: string;
    options?: ActionOptions;
    settings: {
      componentUid: string;
    };
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionComponentForceUpdateConfigurations',
    cids: [data.cid],
    data,
    undo: ({ cid, oldComponent, settings, options }: T) => {
      componentForceUpdateConfigurations({
        cid,
        component: oldComponent,
        options,
        settings,
      });
    },
    redo: ({ cid, newComponent, settings, options }: T) => {
      componentForceUpdateConfigurations({
        cid,
        component: newComponent,
        options,
        settings,
      });
    },
  };
};

export const actionDeleteAllSections = <
  T extends {
    list: SectionInfo[];
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionDeleteAllSections',
    cids: data.list.map((s) => s.section.cid || ''),
    data,
    undo: (data: T) => {
      data.list.forEach((s) => {
        sectionCreate(s.section, s.position, s.options);
      });
    },
    redo: (data: T) => {
      data.list.forEach((s) => {
        if (s.section?.cid) {
          sectionDelete(s.section.cid, s.options);
        }
      });
    },
  };
};

export const actionMoveComponentToNewSection = <
  T extends {
    componentUid: string;
    fromCid: string;
    newFromComponent: string;
    oldFromComponent: string;
    newSection: Section;
    position: { cid?: Maybe<string>; direction: 'before' | 'after' };
    parentData: { fromParent: string; fromIndex: number };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionMoveComponentToNewSection',
    cids: [data.fromCid || '', data.newSection?.cid || ''],
    data,
    undo: ({ componentUid, fromCid, oldFromComponent, newSection, position, parentData, options }: T) => {
      undoMoveComponentToNewSection({
        componentUid,
        fromCid,
        fromComponent: oldFromComponent,
        newSection,
        position,
        parentData,
        options,
      });
    },
    redo: ({ componentUid, fromCid, newFromComponent, newSection, position, parentData, options }: T) => {
      moveComponentToNewSection({
        componentUid,
        fromCid,
        fromComponent: newFromComponent,
        newSection,
        position,
        parentData,
        options,
      });
    },
  };
};

export const componentMoveOnlyToSectionAction = <
  T extends {
    fromCid: string;
    fromOldComponent: string;
    fromNewComponent: string;
    toCid: string;
    toOldComponent: string;
    toNewComponent: string;
    parentData: { toParent: string; toIndex: number; fromParent: string; fromIndex: number; uid: string };
    reverseParentData: { toParent: string; toIndex: number; fromParent: string; fromIndex: number; uid: string };
    options?: ActionOptions;
  },
>(
  data: T,
): Action => {
  return {
    func: 'componentMoveOnlyToSectionAction',
    cids: [data.fromCid, data.toCid],
    data,
    undo: ({ fromCid, fromOldComponent, toCid, toOldComponent, options, reverseParentData }: T) => {
      componentMoveOnlyToSection(fromCid, fromOldComponent, toCid, toOldComponent, reverseParentData, options, true);
    },
    redo: ({ fromCid, fromNewComponent, toCid, toNewComponent, options, parentData }: T) => {
      componentMoveOnlyToSection(fromCid, fromNewComponent, toCid, toNewComponent, parentData, options, true);
    },
  };
};

export const actionReplaceSection = <
  T extends {
    oldSection: Section;
    newSection: Section;
    findIndex: any;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionReplaceSection',
    cids: [data.newSection?.cid || ''],
    data,
    undo: ({ oldSection, newSection, findIndex }: T) => {
      sectionDelete(newSection?.cid || '', {
        noRecordHistory: true,
        preventRemoveEditingComponent: true,
        isHandleThemeSection: oldSection?.isThemeSection || newSection?.isThemeSection,
      });
      sectionCreate(
        oldSection,
        {
          direction: 'before',
          replaceIndex: findIndex,
        },
        {
          noRecordHistory: true,
          isHandleThemeSection: oldSection?.isThemeSection,
        },
      );
    },
    redo: ({ oldSection, newSection, findIndex }: T) => {
      sectionDelete(oldSection?.cid || '', {
        noRecordHistory: true,
        preventRemoveEditingComponent: true,
        isHandleThemeSection: oldSection?.isThemeSection || newSection?.isThemeSection,
      });
      sectionCreate(
        newSection,
        {
          direction: 'before',
          replaceIndex: findIndex,
        },
        {
          noRecordHistory: true,
          isHandleThemeSection: newSection?.isThemeSection,
        },
      );
    },
  };
};

export const actionRestoreVersionHistory = <
  T extends {
    oldSections: Array<Section>;
    newSections: Array<Section>;
    isRestoreFormVersionHistory?: boolean;
    listOfHistory?: Array<Section>;
  },
>(
  data: T,
): Action => {
  return {
    func: 'actionRestoreVersionHistory',
    cids: data.listOfHistory
      ? [...data.listOfHistory.map((s) => s.cid || '')]
      : [...data.newSections.map((s) => s.cid || '')],
    data,
    undo: ({ oldSections, isRestoreFormVersionHistory }: T) => {
      restoreSections(oldSections, { noRecordHistory: true, isRestoreFormVersionHistory });
    },
    redo: ({ newSections, isRestoreFormVersionHistory }: T) => {
      restoreSections(newSections, { noRecordHistory: true, isRestoreFormVersionHistory });
    },
  };
};
