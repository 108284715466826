<script lang="ts" setup>
import { ref, computed } from 'vue';
import { GSyncProgress } from '@gem/uikit';
import { useInfiniteScroll } from '@vueuse/core';
import { replaceImageToSize } from '../../hook/useResizeImage';
import type { ProductOption } from '../../type/common';

export type List = {
  id: string;
  title?: string;
  cursor?: string;
  image?: string;
  options?: ProductOption[];
  status?: string;
};
// props & emit
type Props = {
  value?: {
    title?: string;
    image?: string;
    id?: string;
  };
  list?: List[];
  isLoading?: boolean;
  open?: boolean;
  syncPercent?: number;
  pageType?: string;
  inputSearchComponent?: Object;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'showMore'): void;
  (e: 'refresh'): void;
  (e: 'setValueSelected', id?: string): void;
  (e: 'onSearch', value?: string): void;
}>();
//================ const =================
const productInfinityScroll = ref<HTMLElement | null>(null);
const searchKeyword = ref('');
const productListNotFound = computed(() => props.list?.length == 0);
const isInstant = computed(() => props.pageType === 'STATIC');

//================ methods =================
useInfiniteScroll(
  productInfinityScroll,
  () => {
    // load more
    emit('showMore');
  },
  { distance: 100 },
);
const onSearch = () => {
  setTimeout(() => {
    emit('onSearch', searchKeyword.value);
  }, 200);
};
const setValueSelected = (id?: string) => {
  if (id) {
    emit('setValueSelected', id);
    close();
  }
};
const close = () => {
  clear();
  emit('close');
};
const clear = () => {
  searchKeyword.value = '';
  onSearch();
};
const updateSearchInput = (value: string) => {
  searchKeyword.value = value;
  setTimeout(() => {
    emit('onSearch', searchKeyword.value);
  }, 200);
};
</script>
<template>
  <div>
    <Teleport to="body">
      <g-modal
        :hide-actions="true"
        :is-open="open"
        :show-btn-close="true"
        :show-line-top="false"
        :is-draggable="true"
        body-class="bg-[#EEEEEE] pb-16"
        modal-class="!w-[580px]"
        container-class="absolute !left-[337px] [@media(max-height:465px)]:max-h-full [@media(max-height:465px)]:bottom-16"
        @close="close">
        <template #title>
          <div class="flex h-full w-full items-center">
            <div>
              <p>Select a product</p>
            </div>
            <div class="flex h-full flex-1 items-center justify-end">
              <div
                class="group/button flex h-[52px] w-[52px] flex-shrink-0 cursor-pointer items-center justify-center"
                @click.stop="emit('refresh')">
                <g-button
                  type="ghost"
                  :only-icon="true"
                  :disable="isLoading"
                  class="h-32 w-32"
                  size="medium"
                  data-test="editor-control-modal-button-refresh">
                  <GBaseIcon
                    v-if="isLoading"
                    :class="{ 'animate-spin': isLoading }"
                    name="small-loading"
                    color="#212121"
                    width="16"
                    height="16"></GBaseIcon>
                  <GBaseIcon
                    v-else
                    name="refresh"
                    color="#212121"
                    width="16"
                    height="16"
                    view-box="0 0 16 16"></GBaseIcon>
                </g-button>
              </div>
              <div class="absolute h-24 w-[1px] bg-[#EEEEEE]"></div>
            </div>
          </div>
        </template>
        <div>
          <div class="flex items-center p-16">
            <component
              :is="inputSearchComponent"
              :query-value="searchKeyword"
              data-test="setting-modal-product-input-search"
              placeholder="Search product..."
              input-style="normalLight"
              class="w-full"
              @search="updateSearchInput"
              @clear="clear" />
          </div>
          <div>
            <div class="flex flex-col">
              <g-sync-progress :percent-sync-done="syncPercent" />
              <div
                v-if="!productListNotFound && isInstant"
                class="text-14 text-light-low sticky top-0 flex px-16 pb-12 font-medium">
                <p>Instant Page only show “Active” products.</p>
              </div>
              <perfect-scrollbar ref="productInfinityScroll" class="!h-[53vh]">
                <div class="relative flex flex-1 flex-col gap-16 bg-[#EEEEEE] px-16 pt-0">
                  <div data-test="setting-modal-product-listing" class="flex flex-1 flex-col gap-16">
                    <!--   :class="{ 'border-t': list?.length !== 0, 'border-y': list?.length === 0 }" -->
                    <div
                      v-if="!productListNotFound"
                      data-test="setting-modal-product-latest"
                      class="active:border-primary-300 flex cursor-pointer items-center rounded-[12px] bg-white hover:bg-[#F6F6F6] active:border"
                      @click="setValueSelected('latest')"
                      @keypress="setValueSelected('latest')">
                      <div
                        class="flex h-[72px] w-[72px] flex-shrink-0 items-center justify-center rounded-l-[12px] bg-white">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2.95956 2.96754C3.25065 2.66525 3.64032 2.5 4.04142 2.5H15.9581C16.3592 2.5 16.7489 2.66525 17.04 2.96754C17.3318 3.27063 17.4998 3.68657 17.4998 4.125V10.625C17.4998 10.9702 17.7796 11.25 18.1248 11.25C18.4699 11.25 18.7498 10.9702 18.7498 10.625V4.125C18.7498 3.36995 18.4611 2.6413 17.9404 2.10048C17.4188 1.55886 16.7063 1.25 15.9581 1.25H4.04142C3.29325 1.25 2.58072 1.55886 2.05916 2.10048C1.53837 2.64131 1.24976 3.36995 1.24976 4.125V10.875C1.24976 11.63 1.53837 12.3587 2.05916 12.8995C2.36431 13.2164 2.79791 13.4217 3.1853 13.548C3.58299 13.6776 4.01324 13.75 4.37476 13.75C4.71993 13.75 4.99976 13.4702 4.99976 13.125C4.99976 12.7798 4.71993 12.5 4.37476 12.5C4.16164 12.5 3.86235 12.4539 3.57258 12.3595C3.27251 12.2617 3.06074 12.1375 2.95956 12.0325C2.66769 11.7294 2.49976 11.3134 2.49976 10.875V4.125C2.49976 3.68657 2.66769 3.27063 2.95956 2.96754Z"
                            fill="#676767" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.3531 5.12796C10.5619 5.04283 10.7854 4.9994 11.0108 5.00001C11.4653 5.00028 11.9021 5.1793 12.2251 5.4995C12.5486 5.82013 12.7313 6.25615 12.7313 6.71196V10.5575L15.2919 11.1219C15.9593 11.2689 16.5512 11.6522 16.9559 12.2016C17.3608 12.7511 17.5504 13.4289 17.4881 14.1081L17.1146 18.1821C17.0851 18.5039 16.8152 18.7501 16.492 18.75L9.91311 18.7478C9.71741 18.7478 9.53304 18.656 9.41494 18.5L6.60063 14.7811C6.38337 14.494 6.26072 14.1464 6.25046 13.7862C6.24019 13.4261 6.34281 13.0722 6.54348 12.7731L7.06246 13.1214L6.54413 12.7722C6.67634 12.5759 6.84723 12.4088 7.04614 12.2808C7.24503 12.1528 7.46786 12.0665 7.70099 12.0269C7.93411 11.9874 8.17291 11.9953 8.40291 12.0502C8.63291 12.1052 8.84952 12.2061 9.03946 12.347L9.04045 12.3478L9.28466 12.5298V6.71197C9.28466 6.4859 9.32968 6.2622 9.417 6.05372C9.50431 5.84525 9.63212 5.65628 9.79284 5.49749C9.95354 5.33871 10.144 5.21322 10.3531 5.12796ZM10.825 6.28545C10.8828 6.26189 10.9449 6.24979 11.0077 6.25L11.0098 5.62501V6.25001C11.1366 6.25001 11.2571 6.29999 11.3451 6.38723C11.4329 6.4743 11.4813 6.59121 11.4813 6.71196V11.0598C11.4813 11.3531 11.6853 11.607 11.9718 11.6701L15.0229 12.3426C15.3963 12.4248 15.7255 12.6388 15.9496 12.943C16.1735 13.247 16.2776 13.6206 16.2434 13.9941L15.9219 17.4998L10.2242 17.4979L7.59744 14.0269C7.53659 13.9464 7.50278 13.8499 7.49995 13.7506C7.49713 13.6516 7.52525 13.5536 7.58114 13.4701C7.6181 13.4154 7.66614 13.3683 7.7226 13.332C7.77925 13.2955 7.84309 13.2707 7.91025 13.2593C7.97742 13.2479 8.04625 13.2502 8.11244 13.266C8.17848 13.2818 8.24029 13.3106 8.29416 13.3505L9.53619 14.2761C9.72574 14.4174 9.97876 14.4396 10.19 14.3336C10.4013 14.2276 10.5347 14.0114 10.5347 13.775V6.71196C10.5347 6.65193 10.5466 6.59235 10.57 6.53661C10.5933 6.48085 10.6277 6.42985 10.6714 6.38668C10.7151 6.3435 10.7673 6.30901 10.825 6.28545Z"
                            fill="#676767" />
                        </svg>
                      </div>

                      <div class="text-14 w-full truncate p-16 font-medium leading-6 text-[#212121]">
                        Automatic (Latest product)
                      </div>
                    </div>
                    <div
                      v-for="(item, index) in list"
                      :key="index"
                      data-test="setting-modal-product-item"
                      class="active:border-primary-300 flex cursor-pointer items-center rounded-[12px] bg-white hover:bg-[#F6F6F6] active:border"
                      :class="{
                        'border-primary-300 !cursor-default border': item.id == value?.id,
                      }"
                      @click="setValueSelected(item.id)"
                      @keypress="setValueSelected(item.id)">
                      <div
                        class="h-[72px] w-[72px] flex-shrink-0 rounded-l-[12px] bg-white"
                        :class="{
                          'flex items-center justify-center': !item.image,
                        }">
                        <img
                          v-if="item.image"
                          class="h-full w-full rounded-l-[12px]"
                          :src="replaceImageToSize(item.image, '72x72') ?? item.image"
                          alt="collections" />
                        <!-- <img v-else src="../images/defaultProduct.png" alt="product" /> -->
                        <svg
                          v-else
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.6985 12.6684L18.4448 19.1091C18.653 19.4672 19.1121 19.5886 19.4702 19.3804C19.8282 19.1721 19.9497 18.713 19.7414 18.355L15.7924 11.5656C15.326 10.7637 14.1793 10.7314 13.6685 11.5058L11.1029 15.3953L9.58381 14.0553C9.01799 13.5562 8.14232 13.6684 7.72052 14.2939L5.01074 18.3128C4.77917 18.6562 4.86986 19.1223 5.21329 19.3539C5.55673 19.5855 6.02286 19.4948 6.25443 19.1513L8.80506 15.3686L10.7577 17.091C10.9206 17.2346 11.1377 17.3006 11.3529 17.2719C11.5681 17.2433 11.7604 17.1227 11.8799 16.9415L14.6985 12.6684Z"
                            fill="#676767" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.50732 5.24986C6.05758 5.24986 4.88232 6.42512 4.88232 7.87486C4.88232 9.32461 6.05758 10.4999 7.50732 10.4999C8.95707 10.4999 10.1323 9.32461 10.1323 7.87486C10.1323 6.42512 8.95707 5.24986 7.50732 5.24986ZM6.38232 7.87486C6.38232 7.25354 6.886 6.74986 7.50732 6.74986C8.12864 6.74986 8.63232 7.25354 8.63232 7.87486C8.63232 8.49618 8.12864 8.99986 7.50732 8.99986C6.886 8.99986 6.38232 8.49618 6.38232 7.87486Z"
                            fill="#676767" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.25 3C1.25 2.0335 2.0335 1.25 3 1.25H21C21.9665 1.25 22.75 2.0335 22.75 3V21C22.75 21.9665 21.9665 22.75 21 22.75H3C2.0335 22.75 1.25 21.9665 1.25 21V3ZM3 2.75C2.86193 2.75 2.75 2.86193 2.75 3V21C2.75 21.1381 2.86193 21.25 3 21.25H21C21.1381 21.25 21.25 21.1381 21.25 21V3C21.25 2.86193 21.1381 2.75 21 2.75H3Z"
                            fill="#676767" />
                        </svg>
                      </div>
                      <div class="text-14 w-full truncate p-16 font-medium leading-6 text-[#212121]">
                        {{ item?.title }}
                      </div>
                      <div
                        v-if="item.status?.toLowerCase() !== 'active'"
                        class="text-12 mr-16 rounded-xl bg-[#F4F4F4] p-[2px] px-8 font-medium capitalize leading-5 text-[#676767]"
                        :class="{ 'bg-[#FDB91333] text-[#CA940F]': item.status?.toLowerCase() === 'archived' }">
                        {{ item.status?.toLowerCase() }}
                      </div>
                    </div>
                    <div
                      v-if="isLoading"
                      class="flex items-center border-x border-b border-[#e0e5ee]"
                      :class="{ 'border-y': list?.length !== 0, 'border-b': list?.length === 0 }">
                      <GLoadingPlaceholder width="100%" height="58px"></GLoadingPlaceholder>
                    </div>
                  </div>
                </div>
                <div v-if="productListNotFound" class="flex h-[calc(100%_-_10px)] flex-col items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.4826 61.894C29.0334 61.894 35.9652 54.9622 35.9652 46.4113C35.9652 37.8605 29.0334 30.9287 20.4826 30.9287C11.9318 30.9287 5 37.8605 5 46.4113C5 54.9622 11.9318 61.894 20.4826 61.894ZM20.4826 64.894C30.6903 64.894 38.9652 56.619 38.9652 46.4113C38.9652 36.2037 30.6903 27.9287 20.4826 27.9287C10.275 27.9287 2 36.2037 2 46.4113C2 56.619 10.275 64.894 20.4826 64.894Z"
                      fill="#9E9E9E" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M43.5869 71.6359L30.8672 58.9162L32.9885 56.7949L45.7082 69.5146L43.5869 71.6359Z"
                      fill="#9E9E9E" />
                    <path
                      d="M37.8904 20C37.91 23.3133 35.2402 26.0159 31.9258 26.0355C35.2402 26.0159 37.942 28.6865 37.9615 32C37.942 28.6865 40.6126 25.9841 43.9258 25.9644C40.6126 25.9841 37.91 23.3133 37.8904 20Z"
                      fill="#9E9E9E" />
                    <path
                      d="M10.4794 66C10.4908 67.9328 8.9334 69.5093 7 69.5207C8.9334 69.5093 10.5095 71.0671 10.5208 73C10.5095 71.0671 12.0673 69.4907 14 69.4792C12.0673 69.4907 10.4908 67.9328 10.4794 66Z"
                      fill="#9E9E9E" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18 53H75V11L18 11L18 53ZM18 8C16.3431 8 15 9.34315 15 11V53C15 54.6569 16.3431 56 18 56H75C76.6569 56 78 54.6569 78 53V11C78 9.34315 76.6569 8 75 8H18Z"
                      fill="#9E9E9E" />
                    <path
                      d="M60.2374 17C62.4286 17 64.1992 17.6197 65.5493 18.8592C66.8994 20.0986 67.5745 21.7586 67.5745 23.8391C67.5745 26.163 66.8552 27.8894 65.4165 29.0181C63.9779 30.1248 62.0302 30.6781 59.5735 30.6781L59.4739 33.832H56.8511L56.7183 28.5866H57.6811C59.8723 28.5866 61.5876 28.2546 62.827 27.5906C64.0664 26.9266 64.6862 25.6761 64.6862 23.8391C64.6862 22.5111 64.2878 21.4598 63.491 20.6851C62.6942 19.9105 61.6208 19.5231 60.2706 19.5231C58.8984 19.5231 57.8139 19.8994 57.0171 20.6519C56.2425 21.3823 55.8551 22.3894 55.8551 23.6731H53C53 22.3451 53.2988 21.1831 53.8964 20.1871C54.494 19.169 55.335 18.3833 56.4195 17.83C57.5262 17.2767 58.7988 17 60.2374 17ZM58.1459 40.6379C57.5704 40.6379 57.0835 40.4387 56.6851 40.0403C56.2867 39.6419 56.0875 39.155 56.0875 38.5795C56.0875 38.0041 56.2867 37.5172 56.6851 37.1188C57.0835 36.7204 57.5704 36.5212 58.1459 36.5212C58.6992 36.5212 59.164 36.7204 59.5403 37.1188C59.9387 37.5172 60.1378 38.0041 60.1378 38.5795C60.1378 39.155 59.9387 39.6419 59.5403 40.0403C59.164 40.4387 58.6992 40.6379 58.1459 40.6379Z"
                      fill="#9E9E9E" />
                  </svg>
                  <p class="text-14 font-regular text-[#676767]">No products match your search.</p>
                  <p class="text-14 font-regular text-[#676767]">Please try another keyword</p>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </g-modal>
    </Teleport>
  </div>
</template>
