import { ID } from '@/utils/ID';
import useHistoryStore from '../stores/history';
import HistoryManager from '@gem/history-manager';
import { arrayUnique } from '@/utils/arrayUnique';

const history = HistoryManager();

import useEditorStore from '../../common/stores/editor';
import useSectionStore from '../../common/stores/section';
import type { ActionOptions } from '../../common/utils/types';
import { setHistoryToLocal } from './recovery';
import { sentryCaptureException } from '../../common/use-cases/sentry';
import { validateCounterHistoryCreate } from '../../validater/use-cases/validateCounterHistoryCreate';

export const historyCreate = (data: object, saveToLocal: boolean = true) => {
  validateCounterHistoryCreate(data);
  const editorStore = useEditorStore();
  const newId = ID();
  history.add({
    id: newId,
    createdAt: new Date().getTime(),
    user: {
      name: 'Dac Hai',
    },
    data,
  });

  if (saveToLocal) {
    try {
      const key = `${editorStore.getEditingThemeId}-${editorStore.getEditingPageId}`;
      const data = JSON.stringify(historyReadAll());
      setHistoryToLocal(key, data);
    } catch (e) {
      sentryCaptureException(
        'historyCreate',
        'Set to local error',
        {
          e,
        },
        {
          level: 'error',
        },
      );
    }
  }

  saveCidsByHistoryId(newId);
  editorStore.setAutoSaveEnable(true);
  setUndoRedo();
};

export const historyReadAll = () => {
  return history.getCommands();
};

export const getHistoryIndex = () => {
  return history.getIndex();
};

export const getHistoryLimited = () => {
  return history.getIsLimited();
};

export const historyUndo = (options?: ActionOptions) => {
  history.undo({
    noRecordHistory: true,
    ...options,
  } as ActionOptions);
  updateIdCurrent();
  setUndoRedo();
};

export const historyRedo = (options?: ActionOptions) => {
  history.redo({
    noRecordHistory: true,
    ...options,
  } as ActionOptions);
  updateIdCurrent();
  setUndoRedo();
};

export const historyClear = () => {
  history.clear();
  updateIdCurrent();
  setUndoRedo();
};

export const historyHasUndo = () => {
  return history.hasUndo();
};

export const historyHasRedo = () => {
  return history.hasRedo();
};

export const historyGetIndex = () => {
  return history.getIndex();
};

export const historySetIndex = (index: number) => {
  history.setIndex(index);
};

export const setHistoryLimited = (limited: boolean) => {
  history.setHistoryLimited(limited);
};

const updateIdCurrent = () => {
  const histories = history.getCommands() || [];
  const index = history.getIndex() || 0;
  const historyCurrent = histories[index];
  const id = historyCurrent?.id || '';

  saveCidsByHistoryId(id);
};

const setUndoRedo = () => {
  const historyStore = useHistoryStore();
  historyStore.setHistoryHasRedo(historyHasRedo());
  historyStore.setHistoryHasUndo(historyHasUndo());
};

const getCidsByHistoryId = (historyId: string) => {
  const histories = history.getCommands() || [];
  let cids = histories.find((item) => item.id == historyId)?.data?.cids;
  if (!historyId && histories.length > 0) {
    cids = histories[0].data?.cids;
  }
  return cids;
};
const removeInvalidCid = (historyCids: string[]) => {
  //clean removed local section
  const sectionStore = useSectionStore();
  const histories = history.getCommands() || [];
  return historyCids.filter((cid: string) => {
    const isSectionCidRemain = !!sectionStore.getItemByCid(cid);
    const isRemoteSection = histories.some((item) => {
      const isThisSection = item.data?.data?.section?.cid === cid;
      const isHasCreated = item.data?.data?.section?.createdAt !== undefined;
      return isThisSection && isHasCreated;
    });
    return isSectionCidRemain || isRemoteSection;
  });
};
const saveCidsByHistoryId = (historyId: string) => {
  const historyStore = useHistoryStore();
  const editorStore = useEditorStore();

  nextTick(() => {
    const savedHistoryId = historyStore.getSavedId;
    const historyLimited = history.getIsLimited();
    if (historyId === savedHistoryId && !(historyLimited && !historyId)) {
      return historyStore.clearCids();
    }

    let newHistoryCids = historyStore.getCids.map((cid: string) => cid);
    const cidsFromHistoryId = getCidsByHistoryId(historyId);
    newHistoryCids = arrayUnique([...newHistoryCids, ...cidsFromHistoryId]);
    // newHistoryCids = removeInvalidCid(newHistoryCids);
    historyStore.setCids(newHistoryCids);

    if (editorStore.getSaving) {
      let newWhileSavingCids = historyStore.getWhileSavingCids.map((cid: string) => cid);
      newWhileSavingCids = arrayUnique([...newWhileSavingCids, ...cidsFromHistoryId]);
      historyStore.setWhileSavingCids(newWhileSavingCids);
    }
  });
};
