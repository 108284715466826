import useNotification from '@/composables/useNotification';
import { cloneDeepObject, parseJson } from '@/utils/common';
import { defineStore } from 'pinia';
import { sentryCaptureException } from '../use-cases/sentry';
import type { Component, Section } from '../utils/types';

const textEncoder = new TextEncoder();

export type State = {
  items: Section[];
  deletedIds: {
    id: string;
    cid?: string;
    isCustom?: boolean;
    isCustomNew?: boolean;
  }[];
  linkedThemeSectionIds: string[];
  unlinkThemeSectionIds: string[];
  linkThemeSectionIds: string[];
  listSectionSnapshot: Section[];
  sectionPosition: string[];
};

export const useSectionStore = defineStore('section', {
  state: (): State => ({
    items: [],
    deletedIds: [],
    linkedThemeSectionIds: [],
    unlinkThemeSectionIds: [],
    linkThemeSectionIds: [],
    listSectionSnapshot: [],
    sectionPosition: [],
  }),
  getters: {
    sizeBySection(state) {
      return state.items.reduce((acc, item) => {
        const size = item.component ? textEncoder.encode(item.component).length : 0;
        return {
          ...acc,
          [item.id]: Math.ceil(size / 1024),
        };
      }, {} as Record<string, number>);
    },
    getDeletedIds(state) {
      return state.deletedIds;
    },
    getLinkedThemeSectionIds(state) {
      return state.linkedThemeSectionIds;
    },
    getUnlinkThemeSectionIds(state) {
      return state.unlinkThemeSectionIds;
    },
    getLinkThemeSectionIds(state) {
      return state.linkThemeSectionIds;
    },
    getItems(state) {
      return state.items;
    },
    modals(state) {
      return state.items.filter((v) => {
        const data = parseJson<Component>(v.component ?? '{}');
        return data.tag === 'Dialog';
      });
    },
    stickies(state) {
      return state.items.filter((v) => {
        const data = parseJson<Component>(v.component ?? '{}');
        return data.tag === 'Sticky';
      });
    },
    getScrollToElement(state) {
      return state.items.map((item, index) => {
        const component = parseJson<Component>(item.component ?? '{}');
        return {
          id: component.uid,
          handle: component.label,
          title: component.tag,
          name: item.name,
          isThemeSection: item.isThemeSection,
          index: index,
        };
      });
    },
    getActiveItems(state) {
      return state.items.filter((item) => {
        if (!item.display) {
          return;
        }
        return item;
      });
    },
    getItemByCid(state) {
      return (cid: string) => state.items.find((item) => item.cid === cid);
    },
    getPosition() {
      return (cid: string) => this.getActiveItems.findIndex((item) => item.cid === cid);
    },
    getThemeSections(state) {
      return () => state.items.filter((item) => item.isThemeSection);
    },
    getSectionBefore(state) {
      return (cid: string) => {
        const index = this.getActiveItems.findIndex((item) => item.cid === cid);
        if (index == 0) {
          return null;
        }
        const item = state.items[index - 1];
        return item;
      };
    },
    getSectionAfter(state) {
      return (cid: string) => {
        const index = this.getActiveItems.findIndex((item) => item.cid === cid);
        if (index == this.getActiveItems.length - 1) {
          return null;
        }
        const item = state.items[index + 1];
        return item;
      };
    },

    getSectionSnapshot(state) {
      return state.listSectionSnapshot;
    },

    getSectionPosition(state) {
      return state.sectionPosition;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    moveItem(cid: string, afterCid: string | null) {
      const item = this.items.find((item) => item.cid === cid) as Section; // cache
      // Remove current
      const indexCurrent = this.items.findIndex((item) => item.cid === cid);
      this.items.splice(indexCurrent, 1);

      // Append
      if (afterCid) {
        const anchorIndex = this.items.findIndex((item) => item.cid === afterCid);
        this.items.splice(anchorIndex + 1, 0, item);
      } else {
        this.items.splice(0, 0, item);
      }
    },
    setItem(item: Section, index: number) {
      const existsCid = this.items.find((el) => el.cid == item.cid);
      const existsId = this.items.find((el) => el.id && item.id && el.id == item.id);
      if (existsCid && !item.isThemeSection) {
        sentryCaptureException(
          'setItem',
          'Duplicate cid',
          { item, items: this.items },
          {
            level: 'fatal',
          },
        );
        const { handleError } = useNotification();
        handleError('Duplicate cid');
      } else if (existsId && !item.isThemeSection) {
        sentryCaptureException(
          'setItem',
          'Duplicate id',
          { item, items: this.items },
          {
            level: 'fatal',
          },
        );
        const { handleError } = useNotification();
        handleError('Duplicate id');
      } else {
        this.items.splice(index, 0, item);
      }
    },
    deleteItem(cid: string) {
      const item = this.items.find((item: Section) => item.cid === cid);
      if (item?.id) {
        const clone = cloneDeepObject(item);
        this.deletedIds.push({
          id: clone.id,
          cid: clone.cid,
          isCustom: clone.isCustom,
          isCustomNew: clone.isCustomNew,
        });
      }

      this.items = this.items.filter((item: Section) => item.cid !== cid);
    },
    setLinkedThemeSectionId(value: string[]) {
      this.linkedThemeSectionIds = [...value];
      this.linkedThemeSectionIds = Array.from(new Set(this.linkedThemeSectionIds));
    },
    setUnlinkThemeSectionId(cid: string) {
      const itemIndex = this.items.findIndex((item: Section) => item.cid === cid);

      if (itemIndex === -1) return;

      const currentSection = this.items[itemIndex];
      const isLinked = this.linkedThemeSectionIds.includes(currentSection.id);
      const isWaitToLink = this.linkThemeSectionIds.includes(currentSection.id);
      const isLastThemeSectionOnPage =
        this.items.filter((section: Section) => section.id === currentSection.id)?.length === 1;

      if (isWaitToLink) {
        const index = this.linkThemeSectionIds.indexOf(currentSection.id);
        this.linkThemeSectionIds.splice(index, 1);
      }

      if (isLinked && isLastThemeSectionOnPage) {
        const index = this.unlinkThemeSectionIds.indexOf(currentSection.id);
        if (index === -1) {
          this.unlinkThemeSectionIds.push(currentSection.id);
        }
      }

      this.deleteItem(cid);
    },
    setLinkThemeSectionId(value: string[]) {
      // Remove `sectionId` from unlink list when all theme sections with this `sectionId` was deleted from page
      value.forEach((id: string) => {
        const sectionsFound = this.items.filter((section: Section) => section.id === id)?.length;
        const isWaitToUnlink = this.unlinkThemeSectionIds.includes(id);
        if (isWaitToUnlink && sectionsFound <= 1) this.removeFromUnlinkThemeSectionList(id);
      });

      // Filter out theme section ids have not linked to page
      const filteredValue = value.filter((id: string) => !this.linkedThemeSectionIds.includes(id));

      this.linkThemeSectionIds = Array.from(new Set([...filteredValue]));
    },
    setEmptyUnlinkThemeSection() {
      this.unlinkThemeSectionIds = [];
    },
    removeFromUnlinkThemeSectionList(removedId: string) {
      this.unlinkThemeSectionIds = this.unlinkThemeSectionIds.filter((id) => id !== removedId);
    },
    removeFromLinkThemeSectionList(removedId: string) {
      this.linkThemeSectionIds = this.linkThemeSectionIds.filter((id) => id !== removedId);
    },
    update(newItem: Section) {
      if (this.items?.length) {
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          if (item.cid === newItem.cid) {
            this.items[i] = { ...item, ...newItem };
            break;
          }
        }
      }
    },
    deleteIdInDeleted(id: string) {
      this.deletedIds = this.deletedIds.filter((item) => item.id !== id);
    },
    deleteAll() {
      const newDeletedSections = this.items.map((section) => {
        return { id: section.id, cid: section.cid, isCustom: section.isCustom, isCustomNew: section.isCustomNew };
      });
      this.deletedIds = [...this.deletedIds, ...newDeletedSections];
      this.items = [];
    },
    clearFlagImportFromLibrary() {
      this.items.forEach((item) => {
        if (item.isImportFromLibrary) {
          item.isImportFromLibrary = false;
        }
      });
    },
    replaceItems(items: Section[]) {
      this.items = items;
    },
    clearDeletedIds() {
      this.deletedIds = [];
    },

    setListSectionSnapshot(items: Section[]) {
      const newItems = items.filter((item) => this.listSectionSnapshot.findIndex((el) => el.cid === item.cid) === -1);
      this.listSectionSnapshot = this.listSectionSnapshot.concat(newItems).map((item) => {
        const updatedItem = items.find((el) => el.cid === item.cid);
        return {
          ...item,
          ...updatedItem,
        };
      });
    },

    updateNewData(newItem: Section) {
      this.items?.forEach((item) => {
        if (item.cid !== newItem.cid) {
          return;
        }
        item.id = newItem.id;
        item.createdAt = newItem.createdAt;
        item.deletedAt = newItem.deletedAt;
        item.updatedAt = newItem.updatedAt;
      });
    },

    setSectionPosition(position: string[]) {
      this.sectionPosition = position;
    },

    updateItemName(cid: string, sectionName: string) {
      this.items.forEach((item) => {
        if (item.cid === cid) {
          item.name = sectionName;
        }
      });
    },
  },
});

export default useSectionStore;
