<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { inRange } from './helpers';
const props = defineProps<{
  value: number;
  totalCol: number;
  index: number;
  cols: number[];
  area?: HTMLElement;
}>();

let isDown = false;
const backdrop = ref<HTMLElement | null>(null);
const emit = defineEmits<{
  (e: 'update-position'): void;
  (e: 'on-update-position', index: number, value: number): void;
}>();
const itemTotal = computed(() => {
  let total = 0;
  props.cols.forEach((item) => {
    total += item;
  });
  return total;
});

const onMouseDown = () => {
  if (props.totalCol > 4) return;
  isDown = true;
  document.body.classList.add('cursor-col-resize', 'dragging', 'pointer-events-none');
  document.addEventListener('mousemove', onMouseMove);
  backdrop?.value?.classList.add('bg-[#3C67FF26]');
};
const onMouseMove = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  if (props.area) {
    const rect = props.area.getBoundingClientRect();
    const fullWidth = rect.width;
    const itemWidth = Math.round(fullWidth / itemTotal.value);
    let x = e.clientX - rect.left;
    if (x > rect.width) {
      x = rect.width;
    }
    if (x < 0) {
      x = 0;
    }
    const position = Math.round(x / itemWidth);
    emit('on-update-position', props.index, position);
  }
};

const onMouseUp = () => {
  if (isDown) {
    isDown = false;
    emit('update-position');
    backdrop?.value?.classList.remove('bg-[#3C67FF26]');
  }
  document.body.classList.remove('cursor-col-resize', 'dragging', 'pointer-events-none');
  document.removeEventListener('mousemove', onMouseMove);
};
onMounted(() => {
  document.addEventListener('mouseup', onMouseUp);
});

onUnmounted(() => {
  document.removeEventListener('mouseup', onMouseUp);
});
</script>

<template>
  <div
    draggable="false"
    class="bg-dark-400 text-12 font-regular flex select-none items-center justify-center text-center"
    :class="{
      'pl-16': inRange(totalCol, 4, 1) && index === 0 && value !== 1,
      'pr-16': inRange(totalCol, 4, 1) && index === totalCol - 1 && value !== 1,
    }">
    {{ value }}
  </div>
  <div
    v-if="totalCol > 1 && index !== totalCol - 1"
    draggable="false"
    :class="{ 'cursor-col-resize px-16': inRange(totalCol, 4) }"
    class="group/division relative h-full"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp">
    <div
      :class="{ 'bg-dark-100': totalCol > 4, 'bg-primary-300 cursor-col-resize': inRange(totalCol, 4) }"
      class="h-full w-4 rounded-full" />

    <div
      ref="backdrop"
      class="rounded-medium ac pointer-events-none absolute left-[2px] top-[-2px] h-32 w-32 focus:bg-[#80808033] group-hover/division:bg-[#80808033]"></div>
  </div>
</template>
