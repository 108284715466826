import { defineStore } from 'pinia';

export type State = {
  isOpen: boolean;
  component: {
    uid: string;
    label: string;
    productId: string;
    isThemeSection: boolean;
  };
  position: {
    top: number;
    left: number;
  };
  revertSubmenu?: boolean;
};

export const useContextMenuStore = defineStore('contextMenu', {
  state: (): State => ({
    isOpen: false,
    component: {
      uid: '',
      label: '',
      productId: '',
      isThemeSection: false,
    },
    position: {
      top: 0,
      left: 0,
    },
    revertSubmenu: false,
  }),
  getters: {
    getIsOpen(state) {
      return state.isOpen;
    },
    getComponent(state) {
      return state.component;
    },
    getPosition(state) {
      return state.position;
    },
    getRevertSubmenu(state) {
      return state.revertSubmenu;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    setIsOpen(value: boolean) {
      this.isOpen = value;
    },
    setComponent({
      uid,
      label,
      productId,
      isThemeSection,
    }: {
      uid: string;
      label: string;
      productId: string;
      isThemeSection: boolean;
    }) {
      this.component.uid = uid;
      this.component.label = label;
      this.component.productId = productId;
      this.component.isThemeSection = isThemeSection;
    },
    setPosition(top: number, left: number) {
      this.position.top = top;
      this.position.left = left;
    },
    setRevertSubmenu(value: boolean) {
      this.revertSubmenu = value;
    },
  },
});

export default useContextMenuStore;
