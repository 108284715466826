<script lang="ts" setup>
import { ref, watch } from 'vue';
import { GButton, cn } from '@gem/uikit';
import type * as Types from '../type/graphql';
import type { EdgeNode } from '../type/graphql';
import GalleryModel from './modal/GalleryModel.vue';
import { computed } from 'vue';

export type FileSelectFragment = EdgeNode<
  Pick<
    Types.File,
    | 'backupFileKey'
    | 'backupFilePath'
    | 'createdAt'
    | 'deletedAt'
    | 'fileKey'
    | 'fileName'
    | 'filePath'
    | 'fileType'
    | 'id'
    | 'mimeType'
    | 'size'
    | 'updatedAt'
    | 'isShow'
  >
>;

type Image = {
  filePath: string;
  fileName: string;
  backupFileKey: string;
  width: number;
  height: number;
  isShow: boolean;
};

type PropsType = {
  id: string;
  value?: {
    src?: string;
    backupFileKey?: string;
    width?: string | number;
    height?: string | number;
  };
  devices?: any;
  data?: FileSelectFragment[];
  deleteList?: FileSelectFragment[];
  viewMore?: boolean;
  viewMoreDeleted?: boolean;
  image?: Image;
  isLoadingUpload?: boolean;
  maximumSize?: number;
  allowedFiles?: string[];
  shopId: string;
};
const props = withDefaults(defineProps<PropsType>(), {
  maximumSize: 10 * 1024 * 1024, // 10MB
  allowedFiles: () => [
    'image/avif',
    'image/jpeg',
    'image/tiff',
    'image/bmp',
    'image/gif',
    'image/png',
    'image/webp',
    'image/svg+xml',
  ],
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId: string, value?: PropsType['value']): void;
  (e: 'controlChange', controlId: string, value?: PropsType['value']): void;
  (e: 'controlFocus', controlId: string, value?: PropsType['value']): void;
  (e: 'controlBlur', controlId: string, value?: PropsType['value']): void;
  (e: 'deleteImage', id: string): void;
  (e: 'showMore', value: string): void;
  (e: 'handleChangeListImage', value: string): void;
  (e: 'handleLoadImagesDeleted', value: string): void;
  (e: 'deleteForceImage', id: string): void;
  (e: 'restore', id: string): void;
  (e: 'uploadImage', formData: FormData | null): void;
  (e: 'isLoading', isLoading: boolean): void;
  (e: 'updateImageProp', value: object): void;
  (e: 'deleteItemCancel', id: string): void;
}>();

const defaultImage = 'https://via.placeholder.com/600';
const val = ref(props.value?.src === defaultImage ? '' : props.value?.src);
const imageBackupFileKey = ref(props.value?.backupFileKey);
const imageWidth = ref(props.value?.width);
const imageHeight = ref(props.value?.height);
const galleryModelVisible = ref(false);
const isLoading = ref(props.isLoadingUpload);
const showModal = ref(false);
const alert = ref<'size' | 'format' | ''>('');
const isLinkValid = ref<boolean>(true);
const isLinkHttps = ref<boolean>(true);
const isLoadedImage = ref<boolean>(false);
const refInput = ref<HTMLInputElement>();
const wrapImage = ref();

watch(
  () => props.isLoadingUpload,
  (newVal) => {
    isLoading.value = newVal;
    isLoadedImage.value = false;
  },
);

watch(
  () => props?.image?.filePath,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      modalChange({
        filePath: props?.image?.filePath ?? '',
        width: props?.image?.width ?? 0,
        height: props?.image?.height ?? 0,
        fileName: props?.image?.fileName ?? '',
        backupFileKey: props?.image?.backupFileKey ?? '',
      });
    }
  },
);

watch(val, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    val.value = newVal;
  }
});

const handleChangeFileFromUploader = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;
  const file = target.files.item(0);
  if (!file) return;
  if (!props.allowedFiles.includes(file.type)) {
    alert.value = 'format';
    showModal.value = true;
    return;
  }
  if (file.size > props.maximumSize) {
    alert.value = 'size';
    showModal.value = true;
    return;
  }
  postUploadImage(file);
  isLoading.value = true;
  isLoadedImage.value = false;
  emit('isLoading', true);
};

const postUploadImage = (urlImage: File) => {
  const formData = new FormData();
  formData.append('file', urlImage);
  formData.append('shopID', props.shopId);
  formData.append('appName', 'gemx');
  emit('uploadImage', formData);
};

const changeImageLink = async (imgLink: string) => {
  updateImageLinkToVal(imgLink);
  const isSatisfied = await checkImageSatisfied(imgLink);
  if (isSatisfied) {
    imageBackupFileKey.value = '';
    change();
  }
};

const checkImageSatisfied = async (imgLink: string) => {
  if (!verifyLinkHttps(imgLink)) {
    isLinkHttps.value = false;
    return false;
  }
  const validImg = await verifyImageLinkValid(imgLink);
  if (validImg.isExpired) {
    return false;
  }
  if (!validImg.isValid) {
    isLinkValid.value = false;
    return false;
  }
  imageWidth.value = validImg.width;
  imageHeight.value = validImg.height;
  return true;
};

const updateImageLinkToVal = (imgLink: string) => {
  val.value = imgLink;
  isLinkHttps.value = true;
  isLinkValid.value = true;
};

const verifyLinkHttps = (imgLink: string) => {
  let url: URL;
  try {
    url = new URL(imgLink);
    return url.protocol.includes('https:');
  } catch (e) {
    return false;
  }
};

const verifyImageLinkValid = (
  imgLink: string,
): Promise<{ isValid: boolean; width?: number; height?: number; isExpired?: boolean }> => {
  return new Promise(function (resolve) {
    const TIME_OUT_VERIFY_IMAGE = 2000;
    let timer: ReturnType<typeof setTimeout>;
    const img = new Image();
    const cacheImgLink = val.value;
    img.onerror = img.onabort = function () {
      clearTimeout(timer);
      resolve({ isValid: false, isExpired: cacheImgLink !== val.value });
    };
    img.onload = function () {
      clearTimeout(timer);
      resolve({ isValid: true, width: img.width, height: img.height });
    };
    timer = setTimeout(function () {
      resolve({ isValid: false, isExpired: cacheImgLink !== val.value });
    }, TIME_OUT_VERIFY_IMAGE);
    img.src = imgLink;
  });
};

const openModal = () => {
  galleryModelVisible.value = true;
};
const closeGalleryVisible = (value: boolean) => {
  galleryModelVisible.value = value;
};

const modalChange = ({
  filePath,
  width,
  height,
  backupFileKey,
}: {
  filePath: string;
  width: number;
  height: number;
  fileName: string;
  backupFileKey: string;
}) => {
  updateImageLinkToVal(filePath);
  imageWidth.value = width;
  imageHeight.value = height;
  imageBackupFileKey.value = backupFileKey;
  change();
};

const change = () => {
  emit('controlChange', props.id, {
    src: val.value,
    width: imageWidth.value,
    height: imageHeight.value,
    backupFileKey: imageBackupFileKey.value,
  });
};

const deleteImage = (id: string) => {
  emit('deleteImage', id);
};

const handleChangeListImage = (value: string) => {
  emit('handleChangeListImage', value);
};

const handleLoadImagesDeleted = (value: string) => {
  emit('handleLoadImagesDeleted', value);
};

const handleShowMoreData = (value: string) => {
  emit('showMore', value);
};

const handleForceDeleteItem = (id: string) => {
  emit('deleteForceImage', id);
};

const handleRestoreFile = (id: string) => {
  emit('restore', id);
};

const updateImageProp = (data: object) => {
  emit('updateImageProp', data);
};

const deleteItemCancel = (id: string) => {
  emit('deleteItemCancel', id);
};

const handleClearUrl = () => {
  val.value = '';
  imageBackupFileKey.value = '';
  refInput.value?.focus();

  change();
};

const handleLoadingUpload = () => {
  isLoading.value = true;
  emit('isLoading', true);
};

const closeModal = () => {
  showModal.value = false;
};

const getAlert = (value: 'size' | 'format' | '') => {
  showModal.value = true;
  alert.value = value;
};

const classImage = computed(() => {
  if (wrapImage?.value) {
    const height = Number(imageHeight.value);
    const width = Number(imageWidth.value);
    if (height < 100 && width < 100) return 'h-auto w-auto object-contain';
    const originPercentage = wrapImage?.value?.clientWidth / wrapImage?.value?.clientHeight;
    if (width / height < originPercentage) {
      return 'h-full w-auto object-contain';
    } else {
      return 'w-full h-auto object-contain';
    }
  }
  return '';
});
</script>

<template>
  <div class="gemx-control bg-light-500V2 mb-16 rounded-xl p-16">
    <div class="">
      <div
        ref="wrapImage"
        class="custom-control-image_upload group/upload bg-dark-300 group relative flex h-[126px] w-full items-center justify-center overflow-hidden rounded-xl transition-all hover:bg-[#4A4A4A]"
        :class="{
          'animated-background bg-transparent ': !!val && !isLoadedImage,
          'transparent-bg bg-light-500V2': val && isLoadedImage,
          'hover:bg-light-500V2': val,
        }">
        <div
          v-if="!isLoading"
          data-test="editor-control-btn-upload-image"
          class="custom-upload-image-file absolute flex h-full w-full cursor-pointer items-center justify-between"
          :class="{
            'from-dark-400/80 via-dark-400/20 to-dark-400/80 invisible group-hover:bg-gradient-to-b': val,
          }">
          <input
            v-if="!val"
            id="input"
            class="z-5 absolute m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
            type="file"
            accept="image/*"
            @change.stop="handleChangeFileFromUploader" />
          <div class="dark relative flex h-full w-full">
            <g-button
              v-if="val"
              type="ghost"
              size="small"
              class="absolute right-4 top-4 flex h-32 w-32 items-center justify-center text-red-200"
              @click="handleClearUrl">
              <g-base-icon class="shrink-0" name="trash-20" width="16" height="16" viewBox="0 0 20 20" />
            </g-button>
            <g-button
              type="tertiary"
              size="medium"
              class="absolute left-[50%] h-36 w-[192px] -translate-x-[50%] text-white"
              :class="{
                'bg-dark-400 bottom-[12px]': val,
                'top-[50%] -translate-y-[50%] !bg-transparent': !val,
              }">
              <input
                id="input"
                class="z-5 absolute m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                type="file"
                accept="image/*"
                @change.stop="handleChangeFileFromUploader" />
              <g-base-icon name="upload" width="16" height="16" viewBox="0 0 16 16" />
              <span class="text-12 ml-8 font-medium" :class="val ? 'text-light-100' : 'text-dark-light'"
                >Upload Image</span
              >
            </g-button>
          </div>
        </div>
        <div v-if="isLoading" class="custom-control-loading h-full w-full">
          <div class="custom-control-loading-icon h-full w-full"></div>
        </div>
        <img
          v-if="!isLoading && val"
          :class="cn(!isLoading ? 'h-full w-full object-cover' : '', classImage)"
          :src="val"
          alt="your image"
          @load="isLoadedImage = true" />
      </div>
      <div class="my-16 flex w-full items-center justify-between">
        <div class="relative w-full">
          <g-input
            ref="refInput"
            :value="val"
            input-style="secondary"
            placeholder="https://demo.image"
            type="text"
            icon-position="last"
            :classes="{ '!pr-32': val?.length, 'bg-dark-300': true }"
            @on-change="changeImageLink">
            <template #icon>
              <button v-if="!!val" class="relative right-4 rounded-xl p-6 hover:bg-[#3B3B3B]" @click="handleClearUrl">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.85355 5.14645C5.65829 4.95118 5.34171 4.95118 5.14645 5.14645C4.95118 5.34171 4.95118 5.65829 5.14645 5.85355L7.29289 8L5.14645 10.1464C4.95118 10.3417 4.95118 10.6583 5.14645 10.8536C5.34171 11.0488 5.65829 11.0488 5.85355 10.8536L8 8.70711L10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536C11.0488 10.6583 11.0488 10.3417 10.8536 10.1464L8.70711 8L10.8536 5.85355C11.0488 5.65829 11.0488 5.34171 10.8536 5.14645C10.6583 4.95118 10.3417 4.95118 10.1464 5.14645L8 7.29289L5.85355 5.14645Z"
                    fill="#E2E2E2" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"
                    fill="#E2E2E2" />
                </svg></button
            ></template>
          </g-input>
          <!-- <g-base-icon
            v-if="!!val"
            name="close-round"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            class="text-dark-high hover:bg-dark-250 absolute top-[calc(50%-8px)] right-8 cursor-pointer transition-all duration-200 hover:top-[50%] hover:h-[28px] hover:w-[28px] hover:-translate-y-1/2 hover:rounded-xl hover:p-6"
            @click="handleClearUrl" /> -->
        </div>
      </div>
      <div class="text-12 text-dark-low mt-8 flex items-center justify-center">
        or
        <div
          class="text-primary-200 ml-4 cursor-pointer text-center font-medium"
          data-test="editor-control-btn-browse-gallery"
          @click="openModal">
          Browse gallery
        </div>
      </div>

      <div v-if="!isLinkValid">
        <small class="text-12 text-red-300">Image Source is invalid.</small>
      </div>
      <div v-if="!isLinkHttps">
        <small class="text-12 text-red-300">Image Source must be https.</small>
      </div>
    </div>
    <GalleryModel
      :image-list="props.data"
      :image-deleted-list="props.deleteList"
      :image-source="val"
      :gallery-model-visible="galleryModelVisible"
      :view-more="props.viewMore"
      :view-more-deleted="props.viewMoreDeleted"
      :maximum-size="maximumSize"
      :allowed-files="allowedFiles"
      @close-gallery-visible="closeGalleryVisible"
      @modal-change="modalChange"
      @delete-image="deleteImage"
      @show-more="handleShowMoreData"
      @handle-change-list-image="handleChangeListImage"
      @handle-load-images-deleted="handleLoadImagesDeleted"
      @delete-force-image="handleForceDeleteItem"
      @restore="handleRestoreFile"
      @upload-image="postUploadImage"
      @update-image-prop="updateImageProp"
      @delete-item-cancel="deleteItemCancel"
      @is-loading="handleLoadingUpload"
      @alert="getAlert" />

    <g-modal :is-open="showModal" :hide-header="true" :hide-actions="true">
      <div class="max-w-[430px] p-40 text-center">
        <div v-if="alert === 'size'">
          <div class="text-20 mb-16 font-medium">Your file is too heavy!</div>
          <div class="text-14 flex flex-col justify-center text-[#666]">
            <span>Your file exceed maximum size ({{ maximumSize / (1024 * 1024) }}MB)</span>
            <span>Please crop or compress image/gif to reduce file size</span>
          </div>
        </div>
        <div v-else>
          <div class="text-18 mb-16 font-medium">
            Image format not supported. Please upload images in JPG, JPEG, TIF, TIFF, BMP, GIF, PNG formats.
          </div>
        </div>
        <g-button class="mt-32 w-full justify-center" @click="closeModal">Got it</g-button>
      </div>
    </g-modal>
  </div>
</template>

<style lang="scss" scoped>
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #494949 10%, #252525 18%, #494949 33%);
  background-size: 800px 100%;
  position: relative;
}

.custom-control-image_upload:hover .custom-upload-image-file {
  visibility: visible;
  //background: linear-gradient(to bottom, #252525CC, #25252533, #252525CC);
}

.custom-control-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #0f0f0f;

  &-icon {
    width: 50px;
    height: 50px;
    border: 5px solid;
    border-radius: 50%;
    border-top-color: transparent;
    color: #91d7f2;
    animation: loading 1.2s linear infinite;
  }

  @keyframes loading {
    25% {
      color: #5196a6;
    }
    50% {
      color: #f2f0eb;
    }
    75% {
      color: #f25041;
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.transparent-bg {
  background-image: url('../assets/bg-transparent.png');
}
</style>
