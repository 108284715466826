<template>
  <svg width="58" height="40" viewBox="0 0 58 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_655_98452)">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H55C56.6569 0 58 1.34315 58 3V37C58 38.6569 56.6569 40 55 40H3C1.34315 40 0 38.6569 0 37V3Z"
        fill="currentColor"
        class="group-hover:text-dark-50 text-primary-hover group-[.active]:text-dark-50" />
      <g opacity="0.2">
        <circle cx="54" cy="-1" r="7" fill="#151515" />
        <path
          d="M54.0157 46.2263L42.9516 33.2973C42.577 32.8588 42.1141 32.504 41.593 32.2562C41.0719 32.0084 40.5044 31.8731 39.9274 31.8591C39.3504 31.8451 38.777 31.9527 38.2445 32.175C37.712 32.3973 37.2323 32.7292 36.8369 33.149L31.6633 38.6357L11.4398 14.4732C11.0438 13.9997 10.5462 13.6215 9.98375 13.3667C9.42133 13.1118 8.80862 12.9868 8.1912 13.0011C7.57377 13.0153 6.9675 13.1684 6.41747 13.449C5.86743 13.7295 5.38777 14.1303 5.01416 14.6215L-19.1645 46.3858C-19.6268 46.9947 -19.9098 47.7204 -19.9818 48.4812C-20.0537 49.2421 -19.9117 50.0079 -19.5718 50.6925C-19.2319 51.3771 -18.7075 51.9534 -18.0575 52.3566C-17.4076 52.7598 -16.658 52.9739 -15.8929 52.9748L50.8898 53C51.6753 52.9997 52.4443 52.7746 53.1057 52.3514C53.7672 51.9283 54.2935 51.3247 54.6223 50.6121C54.9512 49.8996 55.0688 49.1078 54.9613 48.3306C54.8538 47.5534 54.5257 46.8231 54.0157 46.2263Z"
          fill="#151515" />
      </g>
      <rect
        x="33.5"
        y="14"
        width="13"
        height="4"
        rx="2"
        fill="currentColor"
        class="text-dark-disabled group-hover:text-dark-low group-[.active]:text-dark-low" />
      <rect
        x="28"
        y="22"
        width="24"
        height="4"
        rx="2"
        fill="currentColor"
        class="text-dark-disabled group-hover:text-dark-low group-[.active]:text-dark-low" />
    </g>
    <defs>
      <clipPath id="clip0_655_98452">
        <path
          d="M0 3C0 1.34315 1.34315 0 3 0H55C56.6569 0 58 1.34315 58 3V37C58 38.6569 56.6569 40 55 40H3C1.34315 40 0 38.6569 0 37V3Z"
          fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
