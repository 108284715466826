<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import InputUnit from './InputUnit.vue';
import Slider from './Slider.vue';
import type { ScreenUnit } from '../type/common';

type PropsType = {
  id: string;
  value?: string | number;
  min?: number;
  max?: number;
  step?: number;
  units?: string[];
  ignoreUnit?: boolean;
  disableOnChange?: boolean; // allow onchange event
  controlChange?: (id: string, value?: any) => void;
  hideUnit?: boolean;
  useOnlyUnitInit?: boolean;
};

const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  min: 0,
  max: Infinity,
  value: '23',
  step: 1,
  disableOnChange: false,
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string, value?: any): void;
  (e: 'controlChange', controlId?: string, value?: any): void;
  (e: 'controlFocus', controlId?: string, value?: any): void;
  (e: 'controlBlur', controlId?: string, value?: any): void;
}>();

const sliderVal = ref<number>(parseFloat(props.value?.toString() ?? '0'));
const inputVal = ref<number | string>(parseFloat(props.value?.toString() ?? '0'));

const latestUnit = ref(props.units?.[0] || (getValueUnit(props.value.toString()) as ScreenUnit) || 'px');

function getValueUnit(value: string) {
  const regex = new RegExp(/(\d*\.?\d+)\s?(px|em|rem|ex|%|in|cn|mm|pt|pc+)/g);
  return regex.test(value) ? value.replace(/(-|\d)+/gi, '') : 'px';
}

const onChange = (value: number) => {
  if (props.disableOnChange) return;

  if (value < props.min) {
    sliderVal.value = props.min;
  } else if (value > props.max) {
    sliderVal.value = props.max;
  } else {
    sliderVal.value = value;
  }

  if (!props.useOnlyUnitInit) {
    inputVal.value = value + latestUnit.value;
  } else {
    inputVal.value = value;
  }
  emit('controlChange', props.id, inputVal.value);
};

const onChangeInputUnit = (_id?: string | number, value?: number, emitFromChild?: boolean) => {
  if (value === undefined) {
    return;
  }

  const valueNotUnit = parseFloat(value?.toString() ?? '0');

  inputVal.value = valueNotUnit;
  if (valueNotUnit) {
    if (valueNotUnit < props.min) {
      sliderVal.value = props.min;
    } else if (valueNotUnit > props.max) {
      sliderVal.value = props.max;
    } else {
      sliderVal.value = valueNotUnit;
    }
  } else {
    sliderVal.value = props.min;
  }

  if (!props.useOnlyUnitInit) {
    inputVal.value = valueNotUnit + latestUnit.value;
    sliderVal.value = valueNotUnit;
  }

  if (!emitFromChild) {
    emit('controlOnChange', props.id, inputVal.value);
  } else {
    props.controlChange?.(props.id, inputVal.value);
  }
};

const initValue = () => {
  if (props.useOnlyUnitInit) {
    emit('controlOnChange', props.id, parseFloat(inputVal.value.toString()));
  } else {
    emit('controlOnChange', props.id, inputVal.value.toString() + latestUnit.value);
  }
};

onMounted(() => {
  initValue();
});

watch(
  () => props.value,
  () => {
    sliderVal.value = parseFloat(props.value?.toString() ?? '0');
    inputVal.value = parseFloat(props.value?.toString() ?? '0');
  },
);
</script>
<template>
  <div class="w-full">
    <slot name="label"></slot>
    <div class="gemx-range-slider flex items-center gap-8" data-test="editor-control-range">
      <div class="min-w-[48px]">
        <InputUnit
          :id="id"
          class="border-transparent"
          input-type="number"
          placeholder=""
          :min="min"
          :max="max"
          :units="units"
          :hide-unit="hideUnit"
          :input-class="hideUnit ? '!w-[48px] !px-0 text-center' : '!w-[62px]'"
          :value="inputVal.toString()"
          :control-change="(idInput, valueInput) => onChangeInputUnit(idInput, valueInput, true)"
          :use-only-unit-init="useOnlyUnitInit"
          @control-on-change="(idInput, valueInput) => onChangeInputUnit(idInput, valueInput, false)" />
      </div>
      <div class="relative flex flex-1 items-center">
        <Slider :min="min" :max="max" :step="step" :model-value="sliderVal" @update:model-value="onChange" />
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<style lang="scss" scoped>
.gemx-range-slider {
  input {
    -webkit-appearance: none;
    width: 100%;
    background: #494949;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #3c67ff;
    border-radius: 100%;
    cursor: pointer;
  }

  input::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #3c67ff;
    border-radius: 100%;
    cursor: pointer;
  }
}
</style>
