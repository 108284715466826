<script lang="ts" setup>
import { computed } from 'vue';

type Actions = {
  label?: string | number;
  type?: 'tertiary' | 'primary';
  size?: 'large' | 'medium' | 'small';
  icon?: string;
  iconPosition?: 'left' | 'right';
  buttonType?: 'link' | 'action';
  actionHandle?: string;
  link?: string;
};
type Steps = {
  label?: string | number;
  type?: string;
  icon?: string;
  value: string;
  actions?: Actions[];
};
type PropsType = {
  steps?: Steps[];
  themePageStatus?: string;
  openUrlEditorShopify: () => void;
};
const props = defineProps<PropsType>();

const isPublished = computed(() => {
  return props.themePageStatus === 'PUBLISHED';
});

const handleClick = (action: Actions) => {
  if (!isPublished.value) return;

  if (action.buttonType == 'link') {
    action.link ? window.open(action.link) : props.openUrlEditorShopify();
    return;
  }
  if (action.buttonType == 'action') {
    switch (action.actionHandle) {
      default:
        break;
    }
  }
  return;
};
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="rounded-medium text-light-200V2 bg-[#212121] p-[12px]">
      <template v-if="props?.steps">
        <div v-for="(item, index) in props.steps" :key="index" class="mb-16 flex last:mb-0">
          <div class="h-[18px] w-[18px]" v-html="item.icon"></div>
          <div class="ml-8">
            <p class="text-small font-regular leading-5 text-[#AAAAAA]">{{ item.label }}</p>
            <template v-if="item.actions">
              <div v-for="(action, indexAction) in item.actions" :key="indexAction" class="mt-8">
                <g-button :disable="!isPublished" :type="action.type" :size="action.size" @click="handleClick(action)">
                  <template v-if="action.iconPosition == 'left'" #prefix>
                    <span class="mr-8" v-html="action.icon"></span>
                  </template>
                  <template #default>
                    <p class="text-12">{{ action.label }}</p>
                  </template>
                  <template v-if="action.iconPosition == 'right'" #suffix>
                    <span class="ml-8" :class="{ 'opacity-[0.3]': !isPublished }" v-html="action.icon"></span>
                  </template>
                </g-button>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <slot name="info"></slot>
  </div>
</template>
