<script lang="ts" setup>
import { ref } from 'vue';
import ColorPicker from './ColorPicker.vue';
import type { SwatchesOptionValue } from '../types';

const emit = defineEmits<{
  (e: 'deleteColor', index: number, position: number): void;
  (e: 'changeColor', index: number, value?: string): void;
}>();

const props = defineProps<{
  item: SwatchesOptionValue;
  index: number;
  isColor?: boolean;
  color?: string;
  isShowInvalid?: boolean;
}>();

const focus = ref(false);

const handleChangeColor = (value: string) => {
  emit('changeColor', props.index, value);
};
const close = () => {
  focus.value = false;
};
const onClick = () => {
  focus.value = true;
};
</script>

<template>
  <g-popover
    :closeable="true"
    :has-arrow="false"
    :no-shadow-box="true"
    placement="bottom-end"
    class="flex-col"
    wrapper-class="translate-x-[9px] translate-y-[-12px]"
    @close="close">
    <div
      class="hover:bg-light-250 border-light-400 group flex h-[64px] cursor-pointer items-center justify-between gap-[12px] rounded-[12px] border p-12 leading-5"
      :class="{
        '!border-primary-300': focus,
        '!border-red-300': isShowInvalid && !color,
      }"
      @click="onClick">
      <span class="line-clamp-2 text-12">{{ item.label }}</span>
      <div
        :class="`flex h-[40px] w-[40px] shrink-0 justify-center rounded-xl border group-hover:bg-[#0000001A] ${
          color ? 'border-light-500' : 'border-[#5B5B5B]'
        }`"
        :style="{ 'background-color': color }">
        <g-base-icon v-if="!color" name="plus-bold" width="12.5px" viewBox="0 0 20 20" fill="#212121"></g-base-icon>
      </div>
    </div>
    <template #content="{}">
      <div
        :style="{
          transform: 'translateX(-100%)',
        }"
        class="shadow-6dp absolute z-[999] min-w-[247px] rounded-[12px] bg-white p-16 text-[#212121]">
        <ColorPicker
          :variant="true"
          :value="color ?? '#ffffff'"
          :is-auto-update-value="true"
          hex-class="text-[#212121]"
          alpha-class="text-[#676767]"
          color-svg-eye-dropper="#212121"
          bg-hover-svg-eye-dropper="hover:bg-[#e4e4e4]"
          @change-color="handleChangeColor" />
      </div>
    </template>
  </g-popover>
</template>
