import useHistoryStore from '../../history/stores/history';
import useSectionStore from '../stores/section';
import useEditorStore from '../stores/editor';
import { builderDeleteSection } from '../../preview/utils/builder';

import { useContextMenuStore } from '../../preview/stores/contextMenu';

import useLibraryStore from '../../library/stores/library';
import { cacheClear } from '../use-cases/cache';
import { historyClear } from '../../history/use-cases/history';
import { historyClearLocalStorage } from '../../history/use-cases/recovery';

export default function useEditor() {
  const historyStore = useHistoryStore();
  const sectionStore = useSectionStore();
  const editorStore = useEditorStore();
  const contextMenuStore = useContextMenuStore();
  const libraryStore = useLibraryStore();

  const onDestroyed = () => {
    const sections = sectionStore.getItems;
    for (let index = 0; index < sections.length; index++) {
      const section = sections[index];
      builderDeleteSection(section);
    }

    sectionStore.clear();
    historyStore.clear();
    editorStore.clear();
    contextMenuStore.clear();
    libraryStore.clear();
    historyClear();
    cacheClear();
    historyClearLocalStorage();
  };
  return {
    onDestroyed,
  };
}
