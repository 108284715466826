import useShopStore from '@/stores/shop';
import { useFetchThemePage } from '../services/useFetchThemePage';
import useEditor from './useEditor';
import { getEmbedAppUrl } from '@/utils/getEmbedAppUrl';
import useEditorStore from '../stores/editor';

export const useBackToDashboard = () => {
  const shopStore = useShopStore();
  const hookEditor = useEditor();

  const shopDomain = computed(() => shopStore.getShopDomain);

  const backToDashboard = () => {
    const embedAppId = import.meta.env.VITE_GEMPAGES_APP_ID;

    if (embedAppId && shopDomain.value) {
      const url = `${getEmbedAppUrl(shopDomain.value)}/dashboard`;
      if (window.top) {
        window.top.location.href = url;
      } else {
        window.location.href = url;
      }
      hookEditor.onDestroyed();
    }
  };

  return {
    backToDashboard,
  };
};

export const useBackToPageList = () => {
  const hookEditor = useEditor();
  const shopStore = useShopStore();
  const editorStore = useEditorStore();
  const { data: themePage } = useFetchThemePage();

  const shopDomain = computed(() => shopStore.getShopDomain);
  const isGemPagesV7 = computed(() => shopStore.getIsGemPagesV7);
  const pageType = computed(() => themePage.value?.themePage?.type);
  const isEditThemeSection = computed(() => editorStore.getIsEditThemeSection);

  const backToPageList = () => {
    const embedAppId = import.meta.env.VITE_GEMPAGES_APP_ID;

    if (embedAppId && shopDomain.value) {
      const embedAppURL = getEmbedAppUrl(shopDomain.value);
      const themeSectionUrl = `${embedAppURL}/theme-section`;

      if (isEditThemeSection.value) {
        if (window.top) {
          window.top.location.href = themeSectionUrl;
        } else {
          window.location.href = themeSectionUrl;
        }
        return;
      }

      const searchParams = new URLSearchParams({
        page_type: pageType.value ?? 'STATIC',
        version: isGemPagesV7.value ? 'v7' : 'v6',
      });

      const url = `${embedAppURL}/pages?${searchParams}`;

      if (window.top) {
        window.top.location.href = url;
      } else {
        window.location.href = url;
      }

      hookEditor.onDestroyed();
    }
  };

  return {
    backToPageList,
  };
};
