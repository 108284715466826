export const PAGE_LIMIT = 18;
export const DEFAULT_SELECTED_TAB = 'templates';
export const devices = [
  {
    label: 'Desktop',
    id: 'desktop',
    icon: `desktop`,
  },
  {
    label: 'Tablet',
    id: 'tablet',
    icon: `tablet`,
  },
  {
    label: 'Mobile',
    id: 'mobile',
    icon: `mobile`,
  },
];

export const responsiveDevicesScreen = [
  {
    value: 'desktop',
    name: 'xl',
    type: 'Desktop',
    icon: 'icon-desktop',
    width: '100%',
    breakpoint: 1200,
    maxWidth: null,
  },
  {
    value: 'tablet',
    name: 'lg',
    type: 'Laptop',
    icon: 'icon-laptop',
    width: '992px',
    breakpoint: 768,
    maxWidth: 1024,
  },
  {
    value: 'mobile',
    name: 'sm',
    type: 'Mobile',
    icon: 'icon-mobile',
    width: '480px',
    breakpoint: 320,
    maxWidth: 767,
  },
];

export const SORT_SAVED_LIBRARY_ACTIVE_ID = 'lastModified';
export const FILTER_SORTS = {
  SAVED_LIBRARY: [
    {
      id: 'lastModified',
      value: 'lastModified',
      name: 'Last Modified',
    },
    {
      id: 'datePublished',
      value: 'datePublished',
      name: 'Date Published',
    },
    {
      id: 'nameAZ',
      value: 'nameAZ',
      name: 'Name A-Z',
    },
  ],
};
