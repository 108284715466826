<script lang="ts" setup>
import { ref } from 'vue';
import type { GlobalSwatchesData, SwatchesOptionType } from './types';
import SwatchesItemColor from './components/SwatchesItemColor.vue';
import { watch } from 'vue';
import SwatchesPreviewColor from './components/SwatchesPreviewColor.vue';

const emit = defineEmits<{
  (e: 'changeData', value: GlobalSwatchesData[]): void;
  (e: 'typeChange', index: number, type: SwatchesOptionType): void;
}>();

const props = withDefaults(
  defineProps<{
    data?: GlobalSwatchesData[];
    index: number;
    optionTitle?: string;
    saveClicked: boolean;
    isDataDiff: boolean;
    showOther: boolean;
    isColorNotSet: boolean;
  }>(),
  {
    data: () => [],
    showOther: false,
    isColorNotSet: false,
  },
);

const dataClone = ref<GlobalSwatchesData[]>(props.data);
const scrollSection = ref();

watch(
  () => props.data,
  () => {
    dataClone.value = props.data;
  },
);

const handleChangeColor = (index: number, value?: string) => {
  if (value) {
    dataClone.value[props.index].optionValues[index].colors = [value];
    change();
  }
};

const change = () => {
  emit('changeData', dataClone.value);
};

watch(
  () => props.isColorNotSet,
  () => {
    if (!scrollSection.value?.$el) {
      return;
    }
    scrollSection.value.$el.scrollTop = 0;
  },
);
</script>

<template>
  <div class="flex">
    <perfect-scrollbar ref="scrollSection" class="box-border flex !h-[554px] shrink-0">
      <div class="w-[360px] p-24">
        <div class="">
          <div class="text-14 mb-8 font-medium">Display style</div>
          <div class="flex h-[48px] w-full items-center rounded-[12px] border p-[12px]">
            <g-base-icon
              name="variant-style-color-title-triple"
              width="70px"
              viewBox="0 0 70 18"
              fill="none"></g-base-icon>
            <span class="text-14 text-light-high pl-12">Color swatch</span>
          </div>
        </div>
        <div class="mt-24">
          <span class="text-light-low text-12 font-medium">Value of this product</span>
          <div v-if="isColorNotSet" class="text-small my-8 flex gap-4 rounded-xl bg-[#FEE4E3] p-8">
            <g-base-icon
              class="m-3 mr-9 shrink-0"
              name="error-transparent"
              width="16"
              height="16"
              view-box="0 0 16 16"
              custom-class="text-[#C3362B]"></g-base-icon>
            <span class="leading-5 text-[#C3362B]"
              >There were blank options. You should pick color to display this product properly</span
            >
          </div>
          <div class="grid grid-cols-2 [&>*:nth-child(-n+2)]:mt-8">
            <template v-for="(item, option) in dataClone[index].optionValues" :key="option">
              <SwatchesItemColor
                v-if="!item.other"
                :item="item"
                :index="option"
                :color="item.colors?.[0]"
                :is-show-invalid="isColorNotSet"
                class="mt-16 odd:mr-8 even:ml-8"
                @change-color="handleChangeColor">
              </SwatchesItemColor>
            </template>
          </div>
        </div>
        <div v-if="showOther" class="mt-16">
          <span class="text-light-low text-12 font-medium">Other value</span>
          <div class="grid grid-cols-2 pb-24 [&>*:nth-child(-n+2)]:mt-8">
            <template v-for="(item, option) in dataClone[index].optionValues" :key="option">
              <SwatchesItemColor
                v-if="item.other"
                :item="item"
                :index="option"
                :color="item.colors?.[0]"
                class="mt-16 odd:mr-8 even:ml-8"
                @change-color="handleChangeColor">
              </SwatchesItemColor>
            </template>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <SwatchesPreviewColor />
  </div>
</template>
