<script lang="ts" setup>
import { computed } from 'vue';
import type { SpacingOption } from './types';
import Value from './Value.vue';

type Prop = {
  id?: string;
  item: {
    class: string;
    pos: string;
    property: string;
  };
  isHover?: boolean;
  isOpenSelectSpacing?: boolean;
  diffValue?: string | number;
  value?: string | number;
  options: SpacingOption[];
  onSelectSpacing?: () => void;
  onControlChange?: () => void;
  onClickSubAction?: () => void;
  controlChangeProp?: () => void;
};

const props = defineProps<Prop>();
const emits = defineEmits(['outside-mouseleave', 'outside-mouseover']);

const gridArea = computed(() => {
  const posArr = props.item.pos.split(' ');

  if (posArr[0] === 'top') {
    return '1 / 2 / 2 / 3';
  } else if (posArr[0] === 'right') {
    return '2 / 3 / 3 / 4';
  } else if (posArr[0] === 'bottom') {
    return '3 / 2 / 4 / 3';
  } else {
    return '2 / 1 / 3 / 2';
  }
});

function handleMouseOver() {
  emits('outside-mouseover', props.item?.property);
}

function handleMouseLeave() {
  if (props.isOpenSelectSpacing) {
    return;
  }

  emits('outside-mouseleave', props.item?.property);
}
</script>

<template>
  <div
    class="flex items-center justify-center place-self-center"
    :style="{
      'grid-area': gridArea,
    }">
    <Value
      label="margin"
      :property="item.property"
      :options="options"
      :diff-value="diffValue"
      :value="value"
      :is-hover="isHover"
      :control-change="onControlChange"
      @mouseleave.stop="handleMouseLeave"
      @mouseover.stop="handleMouseOver"
      @select-spacing="onSelectSpacing"
      @control-on-change="controlChangeProp"
      @on-click-sub-action="onClickSubAction" />
  </div>
</template>
