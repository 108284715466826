<script lang="ts" setup>
import NumberUnit from './input/NumberUnit.vue';

type PropsType = {
  id: string;
  value?: string;
  placeholder?: string;
  emptyOnClear?: boolean;
  units?: string[];
  readonly?: boolean;
  useOnlyUnitInit?: boolean;
  min?: number;
  max?: number;
  inputClass?: string;
  controlChange?: (id: string, value?: any) => void;
  hideUnit?: boolean;
  inputType?: string;
  innerLabel?: string;
  focus?: boolean;
  elmDisableBlurAction?: string[];
};

const props = withDefaults(defineProps<PropsType>(), {
  placeholder: 'Auto',
  units: () => ['px'],
  readonly: false,
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId: string, value?: any): void;
}>();

const change = (value?: string) => {
  props.controlChange?.(props.id, value);
};
const onChange = (value?: string) => {
  emit('controlOnChange', props.id, value);
};
</script>
<template>
  <div class="w-full">
    <slot name="label"></slot>
    <NumberUnit
      :inner-label="innerLabel"
      :value="value"
      :min="min"
      :max="max"
      :input-type="inputType"
      :use-only-unit-init="useOnlyUnitInit"
      :input-class="inputClass"
      :units="props.units"
      :readonly="readonly"
      :hide-unit="hideUnit"
      :placeholder="props.placeholder"
      :empty-on-clear="emptyOnClear"
      :focus="focus"
      :elm-disable-blur-action="elmDisableBlurAction"
      @on-change="onChange"
      @change="change" />
    <slot name="info"></slot>
  </div>
</template>
