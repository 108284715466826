<script lang="ts" setup>
import { computed, ref, onUnmounted, watch } from 'vue';
import { GButton } from '@gem/uikit';
import parseUnit, { DEFAULT_UNIT } from '../../helpers/parse-unit';
import type { ScreenUnit } from '../../type/common';
import { useOutsideClick } from '@gem/uikit';

type PropsType = {
  id?: string;
  value?: string;
  placeholder?: string;
  units?: string[];
  readonly?: boolean;
  min?: number;
  max?: number;
  name?: string;
  inputClass?: string;
  useOnlyUnitInit?: boolean;
  hideUnit?: boolean;
  height?: number;
  inputType?: string;
  innerLabel?: string;
  convertNullToMin?: boolean;
  emptyOnClear?: boolean;
  defaultWidth?: string;
  focus?: boolean;
  elmDisableBlurAction?: string[];
};

let inputTimer: ReturnType<typeof setTimeout> | undefined;

const props = withDefaults(defineProps<PropsType>(), {
  units: () => ['px', '%'],
  readonly: false,
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  inputType: 'text',
  convertNullToMin: false,
  emptyOnClear: false,
  defaultWidth: '',
  focus: false,
});

const isFocus = ref(props.focus);
const isHover = ref(false);
const isControlClick = ref(false);
const latestUnit = ref<ScreenUnit | undefined>(getValueUnit(props?.value) as ScreenUnit);

const inputEl = ref<HTMLInputElement>();

const previousInputValue = ref<string>(props.value ? props.value : '');

const emit = defineEmits<{
  (e: 'onChange', value?: any): void;
  (e: 'change', value?: any, type?: string): void;
}>();

const handleEmit = (type: 'change' | 'onChange' | 'blur', value?: string) => {
  if (type === 'blur') {
    emit('change', value);
  } else if (type === 'change') {
    emit('change', value);
  } else {
    emit('onChange', value);
  }
};

const initValue = (value?: string) => {
  if (value === 'Custom' || value === 'Mixed') return value;
  if (value === undefined) return undefined;
  const [textValue, unit] = parseUnit(value);
  if (DEFAULT_UNIT.includes(unit)) latestUnit.value = unit as ScreenUnit;
  if (DEFAULT_UNIT.includes(unit) && props.units.includes(unit)) return textValue;
  if (!DEFAULT_UNIT.includes(unit)) return textValue;
  if (DEFAULT_UNIT.includes(unit) && !props.units.includes(unit)) return `${textValue} ${unit}`;
  return textValue;
};

function getValueUnit(value?: string) {
  if (value === undefined) return undefined;
  const unit = parseUnit(value)[1];

  if (DEFAULT_UNIT.includes(unit) || !unit) return unit;
  return props.units[0];
}

const inputValue = computed(() => {
  return initValue(props?.value);
});

const heightClass = computed(() => (props.height ? `h-[${props.height}px]` : 'h-[36px]'));

const unit = computed(() => {
  return props?.value ? getValueUnit(props?.value) : latestUnit.value;
});

const defaultWidthValue = computed(() => parseUnit(props.defaultWidth)[0]?.toString());

const checkRangeValue = (value: string | undefined): string | undefined => {
  if (Number(value) > props.max) return props.max.toString();
  if (Number(value) < props.min) return props.min.toString();
  if (props.min !== Number.MIN_SAFE_INTEGER && props.min.toString() && !value && props.convertNullToMin)
    return props.min.toString();
  return value;
};

const handleControlClick = (e: Event, inputVal: number) => {
  isControlClick.value = true;
  const [inputValue, unit] = parseUnit((e.target as HTMLInputElement).value);
  const newV = Number(inputValue) ? Number(inputValue) + Number(inputVal) : Number(inputVal);
  const value = checkRangeValue(newV.toString());
  (e.target as HTMLInputElement).value = `${value}${unit ? unit : ''}`;
  handleInputOnChange(e);
};

const handleChangeUnit = (item: string) => {
  latestUnit.value = item as ScreenUnit;
  const [val, _] = parseUnit(inputValue.value ?? '');
  if (val) handleEmit('change', `${val ?? ''}${item}`);
};

const handleInputBlur = () => {
  if (!isFocus.value) return;
  const inputValue = inputEl.value?.value;
  if (isDisableChange(inputValue)) return;
  handleEmit('blur', getValueEmit(inputValue));
};

const handleInputChange = (e: Event) => {
  const inputValue = (e.target as HTMLInputElement).value;
  if (isDisableChange(inputValue)) return;
  handleEmit('change', getValueEmit(inputValue));
};

const handleInputOnChange = (e: Event) => {
  isControlClick.value = false;
  const inputValue = (e.target as HTMLInputElement).value;
  const [value, unitValue] = parseUnit(inputValue);
  let result = value;

  if (inputEl.value) {
    if (unitValue !== '' && value) {
      inputEl.value.value = `${inputValue.replaceAll(unitValue, '').trim()} ${unitValue}`;
    }
  }
  if (value === undefined) {
    result = undefined;
  } else if (typeof value === 'string') {
    result = value;
  } else {
    if (props.useOnlyUnitInit) {
      result = inputValue;
    } else {
      const unitAppend = unitValue && DEFAULT_UNIT.includes(unitValue) ? unitValue : unit.value;
      result = `${value.toString()}${unitAppend ? unitAppend : 'px'}`;
    }
  }
  handleEmit('onChange', result);
};

const handleInputFocus = (e: Event) => {
  const eValue = (e.target as HTMLInputElement).value;

  isFocus.value = true;
  if (eValue === 'Custom') {
    previousInputValue.value = 'Custom';
  }
  if (eValue === 'Mixed') {
    previousInputValue.value = 'Mixed';
  }
  const [value] = parseUnit(eValue);

  if (value != undefined) {
    previousInputValue.value = value.toString();
  }
};

const isDisableChange = (inputValue?: string) => {
  if (isControlClick.value) {
    isControlClick.value = false;
    return true;
  }
  if (inputValue === 'Custom' || inputValue === 'Mixed') return true;
  return false;
};

const getValueWhenDisableEmptyOnClear = () => {
  let emitValue = undefined;
  if (!props.emptyOnClear) {
    emitValue = defaultWidthValue.value ?? previousInputValue.value;
    if (inputEl.value) {
      inputEl.value.value = `${checkRangeValue(emitValue)}`;
    }
    if (!['Custom', 'Mixed'].includes(emitValue)) {
      const valueEmit = checkRangeValue(emitValue);
      emitValue =
        props.useOnlyUnitInit || valueEmit?.toLowerCase() === 'auto' ? valueEmit : `${valueEmit}${latestUnit.value}`;
    }
  }
  if (!emitValue) latestUnit.value = undefined;
  return emitValue;
};

const getValueWithParseUnit = (inputValue: string) => {
  let emitValue = inputValue;
  const [value, unitCurr] = parseUnit(inputValue);
  if (value === undefined) {
    emitValue = `${previousInputValue.value}${latestUnit.value}`;
  } else if (typeof value === 'string') {
    latestUnit.value = undefined;
    emitValue = value;
  } else {
    latestUnit.value = getCurrentUnit(inputValue);
    emitValue = `${value}${latestUnit.value}`;
    if (
      inputEl.value &&
      (value > props.max ||
        value < props.min ||
        inputValue.toString() != value.toString() ||
        unitCurr != latestUnit.value)
    ) {
      if (DEFAULT_UNIT.includes(latestUnit.value) && !props.units.includes(latestUnit.value)) {
        inputEl.value.value = `${checkRangeValue(value.toString())} ${latestUnit.value}`;
        emitValue = inputEl.value.value.replace(/ /g, '');
      } else {
        inputEl.value.value = `${checkRangeValue(value.toString())}`;
        emitValue = `${inputEl.value.value}${latestUnit.value}`;
      }
    }

    if (props.useOnlyUnitInit) {
      emitValue = `${checkRangeValue(value.toString())}`;
    }
  }
  const [val, unitVal] = parseUnit(emitValue);
  previousInputValue.value = val as string;
  if (inputEl.value) {
    if (DEFAULT_UNIT.includes(unitVal) && !props.units.includes(unitVal)) inputEl.value.value = `${val} ${unitVal}`;
    else inputEl.value.value = previousInputValue.value;
  }
  return emitValue;
};

const getValueEmit = (inputValue?: string): string | undefined => {
  if (!inputValue) return getValueWhenDisableEmptyOnClear() || '';
  else return getValueWithParseUnit(inputValue);
};

const getCurrentUnit = (inputValue: string) => {
  const [_, unitValue] = parseUnit(inputValue);
  const unitAppend = unitValue && DEFAULT_UNIT.includes(unitValue) ? unitValue : unit.value;
  return unitAppend ? (unitAppend as ScreenUnit) : 'px';
};

const updateUnit = (newUnit: ScreenUnit | undefined) => {
  latestUnit.value = newUnit;
};

defineExpose({ updateUnit });

onUnmounted(() => {
  if (inputTimer) {
    clearTimeout(inputTimer);
  }
});

useOutsideClick(inputEl, handleInputBlur, { detectIframe: true, containSelectors: props.elmDisableBlurAction });

watch(
  () => props.focus,
  (value) => value && inputEl.value?.focus(),
);

watch(
  () => props.value,
  (value) => {
    if (value) latestUnit.value = getValueUnit(value) as ScreenUnit;
    else latestUnit.value = undefined;
  },
);
</script>

<template>
  <section class="gemx-control gemx-control-input-unit">
    <slot name="label"></slot>
    <div class="gemx-control-bound">
      <div
        class="gemx-control-input-unit_container group relative rounded-xl"
        :class="heightClass"
        @mouseover="isHover = true"
        @mouseleave="isHover = false">
        <input
          v-if="innerLabel"
          :value="innerLabel"
          disabled
          class="text-12 text-dark-disabled bg-dark-400 w-full rounded-l-xl pl-8" />
        <input
          ref="inputEl"
          data-test="editor-control-number-unit-input"
          :value="inputValue"
          :name="name"
          :style="{
            paddingRight: `${units?.length * 28 || 8}px`,
          }"
          class="caret-primary-300 text-12 placeholder:text-dark-disabled focus:!border-primary-300 focus:!bg-dark-400 group-hover:border-dark-200 group-hover:bg-dark-200 disabled:border-dark-200 disabled:bg-dark-200 text-dark-high bg-dark-400 border-dark-400 w-[calc(100%_-_2px)] rounded-r-xl border px-8 outline-none transition-colors duration-200 disabled:cursor-not-allowed"
          :type="inputType"
          :class="[
            {
              'text-center': innerLabel,
              'rounded-xl': !innerLabel,
            },
            `${inputClass} ${heightClass}`,
          ]"
          :placeholder="placeholder"
          :disabled="readonly"
          @focus="handleInputFocus"
          @focusout="isFocus = false"
          @input="handleInputOnChange"
          @keydown.enter="handleInputChange"
          @keydown.tab="handleInputChange"
          @keydown.up="(e) => handleControlClick(e, 1)"
          @keydown.down="(e) => handleControlClick(e, -1)" />
        <div
          v-if="units?.length > 0 && !hideUnit"
          class="text-12 placeholder:text-dark-high disabled:border-dark-200 text-dark-high dark absolute right-0 z-10 flex items-center rounded-xl bg-transparent outline-none transition-colors duration-200 hover:z-[99] disabled:cursor-not-allowed"
          :class="heightClass">
          <template v-if="units?.length > 1">
            <g-button
              v-for="item in units"
              :key="item"
              data-test="editor-control-number-unit-option"
              button-type="button"
              type="secondary"
              class="!text-12 text-dark-disabled hover:dark:!text-light-100 mr-4 flex h-24 w-24 items-center justify-center border-transparent bg-transparent !px-0 font-medium last:mr-8"
              :button-classes="item !== unit ? 'dark:!text-dark-disabled' : 'dark:!text-dark-high'"
              @click.stop="handleChangeUnit(item)"
              >{{ item }}
            </g-button>
          </template>
          <template v-else>
            <div class="mr-8 w-[30px] py-4 pl-[3px] text-center">
              <span class="!text-12 text-dark-disabled w-10 cursor-default font-medium" :class="heightClass">
                {{ units[0] }}
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.gemx-control-input-unit {
  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-moz-placeholder,
  :-ms-input-placeholder {
    color: #7f7f7f;
  }

  .gemx-control-bound {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    transition: all 0.25s;

    .gemx-control-input-unit_container {
      position: relative;
      display: flex;
      width: 100%;
    }

    input {
      z-index: 5;

      &:focus {
        z-index: 7;
      }
    }
  }
}
</style>
