<script lang="ts" setup>
import type { OptionItem } from '../type/common';

type PropsType = {
  id: string;
  value?: any;
  options?: OptionItem[];
  disableToggle?: boolean;
  readonly?: boolean;
  iconSmall?: boolean;
  iconCustom?: boolean;
  backgroundClass?: string;
};
const props = defineProps<PropsType>();
const emit = defineEmits<{
  (e: 'controlOnChange', controlId: string, value?: string): void;
  (e: 'controlChange', controlId: string, value?: string): void;
  (e: 'controlFocus', controlId: string, value?: string): void;
  (e: 'controlBlur', controlId: string, value?: string): void;
}>();

const change = (v?: string) => {
  if (props.value === v || props.disableToggle) {
    return;
  }

  emit('controlChange', props.id, v);
};
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div
      data-test="editor-control-segment"
      class="flex h-36 w-full items-center gap-4 rounded-xl p-4"
      :class="backgroundClass || 'bg-dark-400'">
      <template v-for="item in options" :key="item.value">
        <div
          data-test="editor-control-segment-option"
          class="text-12 hover:text-light-hover flex h-[28px] flex-1 cursor-pointer select-none items-center justify-center rounded-[6px] text-center font-medium transition-all"
          :class="
            value === item.value
              ? '!bg-dark-200 !text-light-hover'
              : `text-dark-disabled ${backgroundClass || 'bg-dark-400'}`
          "
          @click.stop="change(item.value)">
          <g-tooltip placement="top" :disabled="!item.tooltip" class="!flex h-full w-full items-center justify-center">
            <span
              v-if="item.icon && !iconCustom"
              :class="{
                '[&_>_svg]:h-20': !iconSmall,
                '[&_>_svg]:h-12': iconSmall,
                'first:[&_>svg_>rect]:stroke-dark-100': value === item.value && id === 'position',
              }"
              v-html="item.icon"></span>
            <span v-else-if="item.icon && iconCustom" v-html="item.icon"></span>
            <g-base-icon
              v-else-if="item.baseIcon"
              :name="item.baseIcon"
              :width="item.width || '20'"
              :height="item.height || '20'"
              :view-box="item.viewBox || '0 0 20 20'"></g-base-icon>
            <g-base-icon
              v-else-if="item.type"
              :name="((`${item.type}-${item.value}`) as any)"
              :width="item.width || '20'"
              :height="item.height || '20'"
              :view-box="item.viewBox || '0 0 20 20'"></g-base-icon>
            <span v-else class="[&_>_svg]:h-20" v-html="item.label"></span>
            <template #content>
              <div class="text-light-100 text-12">{{ item.tooltip }}</div>
            </template>
          </g-tooltip>
        </div>
      </template>
    </div>
    <slot name="info"></slot>
  </div>
</template>
