<script setup lang="ts">
import { InputUnit, NumberUnit } from '@gem/control';
import { ref, computed } from 'vue';
import type { ScreenType } from '../types';
import { GInput } from '@gem/uikit';

type Props = {
  id: string;
  value?: string;
  hideUnit?: boolean;
  inputType?: 'text' | 'number';
  units?: string[];
  displayOptions?: {
    label: string;
    value: string;
    reversed?: boolean;
    showValue?: boolean;
  }[];
  placeholder?: string;
  currentScreen?: ScreenType;
  globalStyleContainerWidth?: string;
  useOnlyUnitInit?: boolean;
  readonly?: boolean;
  min?: number;
  max?: number;
};

const props = withDefaults(defineProps<Props>(), {
  hideUnit: true,
  inputType: 'text',
  readonly: false,
  placeholder: 'Auto',
  units: () => ['px'],
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
});

const inputTag = props.inputType === 'text' ? GInput : InputUnit;

const realValue = ref(props.value);

const globalStyleContainerWidth = computed<string>(() => props.globalStyleContainerWidth || '');

const displayValue = computed(() => {
  return props.value === 'default' ? (globalStyleContainerWidth.value as string) : props.value;
});

const emit = defineEmits<{
  (e: 'change', controlId: string, value?: string): void;
  (e: 'onChange', controlId: string, value?: any): void;
}>();

function reversedValue(reversed?: boolean) {
  if (!reversed) return false;
  const parts = realValue.value?.split(',');
  const reversedString = parts?.reverse().join(',');
  return reversedString;
}

const handleInputChange = (type: 'change' | 'onChange', id: string, value?: string) => {
  updateRealValue(value);
  type === 'change' ? emit('change', id, realValue.value) : emit('onChange', id, realValue.value);
};

const handleChangeValue = (newValue: string) => {
  updateRealValue(newValue);
  emit('change', props.id, realValue.value);
  emit('onChange', props.id, realValue.value);
};

function controlChange(value?: string) {
  updateRealValue(value);
  if (props.inputType === 'text') emit('onChange', props.id, value ? value : realValue.value);
  else emit('change', props.id, value ? value : realValue.value);
}

const updateRealValue = (value?: any) => {
  realValue.value = isGlobalValue(value) ? 'default' : value;
};

const isGlobalValue = (value?: string) => {
  return value === 'default' || value === globalStyleContainerWidth.value;
};

const isDefaultValue = (value: string) => {
  if (value === 'default' && isGlobalValue(realValue.value)) return true;
  return false;
};
</script>

<template>
  <div class="max-w-input-horizontal w-full">
    <g-popover
      :has-arrow="false"
      :closeable="true"
      :overlay="false"
      overlay-container="#root-modal"
      wrapper-class="translate-x-[-172px] translate-y-[-10px]"
      placement="bottom-end">
      <template #default="{}">
        <component
          :is="inputTag"
          :id="id"
          input-class="!w-full"
          ref="refInput"
          :value="displayValue"
          :units="units"
          :min="min"
          :max="max"
          :use-only-unit-init="useOnlyUnitInit"
          :empty-on-clear="true"
          :readonly="readonly"
          :hide-unit="hideUnit"
          :placeholder="placeholder"
          :control-change="(id: string, value: string) => handleInputChange('change', id, value)"
          @control-on-change="(id: string, value: string) => handleInputChange('onChange', id, value)"
          @handle-blur="props.inputType === 'text' ? controlChange : undefined"
          @on-change="props.inputType === 'text' ? controlChange : undefined"
          @change="props.inputType === 'text' ? controlChange : undefined"></component>
      </template>
      <template v-if="displayOptions?.length" #content="{ close }">
        <div class="rounded-medium bg-dark-400 shadow-4dp absolute w-[178px] p-8 transition-all">
          <template v-for="(opt, index) in displayOptions" :key="index">
            <div
              class="rounded-medium text-12 hover:bg-light-100/20 relative flex h-36 w-full cursor-pointer items-center truncate whitespace-nowrap pl-40 pr-8"
              @click="
                () => {
                  handleChangeValue(opt.value);
                  close();
                }
              ">
              <span class="text-dark-high absolute inset-y-0 left-8 flex w-full items-center gap-12 pr-8">
                <div class="h-20 w-20 shrink-0">
                  <svg
                    v-if="
                      opt.value === reversedValue(opt?.reversed) ||
                      opt.value.toLowerCase() === realValue ||
                      isDefaultValue(opt.value)
                    "
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.8415 5.1766C16.0528 5.41207 16.0528 5.79383 15.8415 6.0293L7.95016 14.8234C7.84869 14.9365 7.71107 15 7.56757 15C7.42408 15 7.28645 14.9365 7.18499 14.8234L4.15846 11.4505C3.94717 11.215 3.94718 10.8333 4.15848 10.5978C4.36978 10.3623 4.71236 10.3623 4.92365 10.5978L7.56759 13.5443L15.0764 5.1766C15.2877 4.94113 15.6302 4.94113 15.8415 5.1766Z"
                      fill="#00C853"></path>
                  </svg>
                </div>
                <p class="flex w-full items-center justify-between truncate">
                  <span class="text-light-200V2">{{ opt.label }}</span>
                  <span v-if="opt.showValue" class="text-dark-low pr-8">{{
                    opt.value === 'default' ? globalStyleContainerWidth : opt.value
                  }}</span>
                </p>
              </span>
            </div>
          </template>
        </div>
      </template>
    </g-popover>
  </div>
</template>
