<template>
  <svg width="58" height="40" viewBox="0 0 58 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_655_98446)">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H55C56.6569 0 58 1.34315 58 3V37C58 38.6569 56.6569 40 55 40H3C1.34315 40 0 38.6569 0 37V3Z"
        fill="currentColor"
        class="group-hover:text-dark-50 text-primary-hover group-[.active]:text-dark-50" />
      <g opacity="0.2">
        <circle cx="4" cy="-1" r="7" fill="#151515" />
        <path
          d="M3.98427 46.2263L15.0484 33.2973C15.423 32.8588 15.8859 32.504 16.407 32.2562C16.9281 32.0084 17.4956 31.8731 18.0726 31.8591C18.6496 31.8451 19.223 31.9527 19.7555 32.175C20.288 32.3973 20.7677 32.7292 21.1631 33.149L26.3367 38.6357L46.5602 14.4732C46.9562 13.9997 47.4538 13.6215 48.0163 13.3667C48.5787 13.1118 49.1914 12.9868 49.8088 13.0011C50.4262 13.0153 51.0325 13.1684 51.5825 13.449C52.1326 13.7295 52.6122 14.1303 52.9858 14.6215L77.1645 46.3858C77.6268 46.9947 77.9098 47.7204 77.9818 48.4812C78.0537 49.2421 77.9118 50.0079 77.5718 50.6925C77.2319 51.3771 76.7075 51.9534 76.0575 52.3566C75.4076 52.7598 74.658 52.9739 73.8929 52.9748L7.11024 53C6.32473 52.9997 5.55574 52.7746 4.89426 52.3514C4.23279 51.9283 3.70649 51.3247 3.37766 50.6121C3.04882 49.8996 2.93119 49.1078 3.03869 48.3306C3.14619 47.5534 3.47433 46.8231 3.98427 46.2263Z"
          fill="#151515" />
      </g>
      <rect
        x="11.5"
        y="14"
        width="13"
        height="4"
        rx="2"
        fill="currentColor"
        class="text-dark-disabled group-hover:text-dark-low group-[.active]:text-dark-low" />
      <rect
        x="6"
        y="22"
        width="24"
        height="4"
        rx="2"
        fill="currentColor"
        class="text-dark-disabled group-hover:text-dark-low group-[.active]:text-dark-low" />
    </g>
    <defs>
      <clipPath id="clip0_655_98446">
        <path
          d="M0 3C0 1.34315 1.34315 0 3 0H55C56.6569 0 58 1.34315 58 3V37C58 38.6569 56.6569 40 55 40H3C1.34315 40 0 38.6569 0 37V3Z"
          fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
